import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";

class VipList extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half bg-black d-table w-100">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Seznam VIP výhod </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Zde najdeš celkový seznam všech výhod v našem VIP!</p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item active" aria-current="page">VIP</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section">
					<div className="container">
					<div className="alert alert-outline-danger" role="alert">Od 25.5.2024 se již VIP nedá zakoupit.</div>
						<table className="table table-bordered">
							<thead>
								<tr>
									<th scope="col" className="text-center bg-light">#</th>
									<th scope="col" className="bg-light">Výhoda</th>
									<th scope="col" className="text-center bg-light">Diamond VIP</th>
									<th scope="col" className="text-center bg-light">Emerald VIP</th>
									<th scope="col" className="text-center bg-light">Obsdian VIP</th>
									<th scope="col" className="text-center bg-light">Amethyst VIP</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th className="text-center">1.</th>
									<td>Prefix v chatu a tablistu</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">2.</th>
									<td>Připojení na plné servery</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">3.</th>
									<td>Glowing postavy <code className="text-blue font-weight-bold">/glow</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">4.</th>
									<td>Získání vlastní hlavy <code className="text-blue font-weight-bold">/skull</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">5.</th>
									<td>Nasazení bloku nebo itemu na hlavu <code className="text-blue font-weight-bold">/hat</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">6.</th>
									<td>Barevné psaní do chatu, tabulky, kovadliny, knihy</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">7.</th>
									<td>Nastavení barvy psaní <code className="text-blue font-weight-bold">/chatcolor</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">8.</th>
									<td><code className="text-dark font-weight-bold">ArmorStandEditor</code> - úprava armorstandů</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">9.</th>
									<td>Možnost zobrazit držený item v chatu - <code className="text-blue font-weight-bold">:item:</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">10.</th>
									<td>VIP Cape v <code className="text-blue font-weight-bold">/cosmetics</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">11.</th>
									<td>No drop itemů (neplatí pro PvP + Vanilla servery)</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">12.</th>
									<td>Enderchest <code className="text-blue font-weight-bold">/ec</code> & Workbench <code className="text-blue font-weight-bold">/wb</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">13.</th>
									<td>Virtuální Beacon <code className="text-blue font-weight-bold">/beacon</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">14.</th>
									<td>Fly libovolně po lobby</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">15.</th>
									<td>Join zpráva o připojení na lobby s volitelným textem</td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">16.</th>
									<td>Limit Homes přes <code className="text-blue font-weight-bold">/sethome</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/> (3)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (4)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (5)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (neomezeně)</td>
								</tr>
								<tr>
									<th className="text-center">17.</th>
									<td><code className="text-dark font-weight-bold">Warps</code> - možnost nastavit si na serveru veřejné warpy</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (2)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (4)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (6)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (12)</td>
								</tr>
								<tr>
									<th className="text-center">18.</th>
									<td>Nastavení vlastního času - <code className="text-blue font-weight-bold">/ptime</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">19.</th>
									<td>Inspekce zníčených bloků pomocí <code className="text-blue font-weight-bold">/co inspect</code></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">20.</th>
									<td>
										<code className="text-dark font-weight-bold">Glowing Items</code> - chceš aby ti svítily i itemy? OK! <code className="text-blue font-weight-bold">/gi</code>
									</td>
									<td className="text-center"><i className="fas fa-times text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">21.</th>
									<td>Přístup k WorldEditu na Creativu</td>
									<td className="text-center"><i className="fas fa-times text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">22.</th>
									<td>Maximální počet pozemků na Creativu</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (200)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (200)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (250)</td>
									<td className="text-center"><i className="fas fa-check text-success"/> (neomezeně)</td>
								</tr>
								<tr>
									<th className="text-center">23.</th>
									<td><code className="text-dark font-weight-bold">ArtMap</code> - kreslení na mapu <code className="text-blue font-weight-bold">/artmap</code></td>
									<td className="text-center"><i className="fas fa-times text-danger"/></td>
									<td className="text-center"><i className="fas fa-times text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								<tr>
									<th className="text-center">24.</th>
									<td><code className="text-dark font-weight-bold">CraftCoins Bonus</code> (měsíčně na lobby)</td>
									<td className="text-center">1.000 CC</td>
									<td className="text-center">1.500 CC</td>
									<td className="text-center">2.000 CC</td>
									<td className="text-center">2.000 CC</td>
								</tr>
								<tr>
									<th className="text-center">25.</th>
									<td><code className="text-dark font-weight-bold">VotePass+</code> (placená verze)</td>
									<td className="text-center"><i className="fas fa-times text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-danger"/></td>
									<td className="text-center"><i className="fas fa-check text-success"/></td>
								</tr>
								{/*
								<tr>
									<th className="text-center bg-light">Ceny za VIP</th>
									<td colSpan={1} className="bg-light"></td>
									<td className="text-center bg-light">Permamentní</td>
									<td className="text-center bg-light">Permamentní</td>
									<td className="text-center bg-light">Permamentní</td>
									<td className="text-center bg-light">40 dní</td>
								</tr>
								<tr>
									<th className="text-center"></th>
									<td>VIP je zakupováno na všechny aktuální i budoucí servery</td>
									<td className="text-center">100 Kč (4 euro)</td>
									<td className="text-center">200 Kč (8 euro)</td>
									<td className="text-center">500 Kč (20 euro)</td>
									<td className="text-center">100 Kč (4 euro)</td>
								</tr> */}
							</tbody>
						</table>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default VipList;
