import React from "react";
import { Component } from "react";

const paintballImage = require('./imgs/paintball.png');
const craftingImage = require('./imgs/rockets.png');
const heroRunningImage = require('./imgs/hero_running.png');

class MainInfoBoxes extends Component<any, any> {

	public render() {
		return (
			<section className="section" style={{paddingTop: '50px'}}>
				<div className="container">

					<div className="container mt-60 pb-5">
						<div className="row align-items-center">

							<div className="col-lg-7 order-1 order-lg-2">
								<div className="position-relative" style={{maxWidth: '500px', transform: 'scaleX(-1)'}}>
									<img src={paintballImage} className="img-fluid mx-auto d-block" alt=""/>
								</div>
							</div>

							<div className="col-lg-5 order-2 order-lg-2 mt-4 pt-2 mt-lg-0 pt-lg-0">
								<div className="section-title mr-lg-4">
									<h1 className="title mb-3">Vytvoř si svůj profil</h1>
									<p className="para-desc text-muted">Začínáš jako nováček s nulovými statistikami a pomocí globáního profilu a statistik, se z tebe může stát <span className="text-primary font-weight-bold">někdo</span>.</p>

									<div className="row">
										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-medal text-success"/>
												</h2>
												<div className="ml-3">
													<h5>Statistiky</h5>
													<p className="text-muted mb-0">Zaznamenáváme přes 300 různých statistik, na zakladě kterých zjistíš jak sis u nás vedl(a) nebo, kdo je lepší?</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-crown text-primary"/>
												</h2>
												<div className="ml-3">
													<h5>Questy</h5>
													<p className="text-muted mb-0">Na každém serveru najdeš stovky questů k plnění, odemkneš si tak lepší výhody i bez VIP.</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="far fa-chart-bar text-danger"/>
												</h2>
												<div className="ml-3">
													<h5>Leaderboardy</h5>
													<p className="text-muted mb-0">Porovnávej svoje statistiky v leaderboardech a buď lepší, nebo chceš snad být nejlepší?</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="container mt-60 pb-5">
						<div className="row align-items-center">

							<div className="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
								<div className="section-title mr-lg-4">
									<h1 className="title mb-3">Různé variace serverů</h1>
									<p className="para-desc text-muted">Snažíme se, aby jsme nabídli všem hráčům možnost hraní jak chtějí sami. Proto u nás najdeš upravené servery, které jinde ne.</p>

									<div className="row">
										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-server text-warning"/>
												</h2>
												<div className="ml-3">
													<h5>Nejnovější verze</h5>
													<p className="text-muted mb-0">Všechny hráče zajímá, co se přidalo v posledních verzích. Updatujeme proto všechny servery vždy na nejnovější verzi do 6 měsíců od vydání verze.</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-dice-d6 text-primary"/>
												</h2>
												<div className="ml-3">
													<h5>Overpowered nebo Vanilla?</h5>
													<p className="text-muted mb-0">Dělíme módy nebo-li servery na tři základní kategorie, dle kterých servery nabízíme. Například Survival (OP), Vanilla: Lands (Bezpečná vanilla s ochranou), Vanilla: Anarchy (pravá Vanilla)</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-lg-7 order-1 order-lg-1">
								<div className="position-relative" style={{maxWidth: '500px', paddingLeft: '30px'}}>
									<img src={craftingImage} className="img-fluid mx-auto d-block" alt=""/>
								</div>
							</div>
						</div>
					</div>

					<div className="container mt-60 pb-5">
						<div className="row align-items-center">

							<div className="col-lg-7 order-1 order-lg-2">
								<div className="position-relative" style={{maxWidth: '450px', transform: 'scaleX(-1)'}}>
									<img src={heroRunningImage} className="img-fluid mx-auto d-block" alt=""/>
								</div>
							</div>

							<div className="col-lg-5 order-2 order-lg-2 mt-4 pt-2 mt-lg-0 pt-lg-0">
								<div className="section-title mr-lg-4">
									<h1 className="title mb-3">Vlastní Resource Pack</h1>
									<p className="para-desc text-muted">Na serveru máme také Resource Pack, se kterým upravujeme server tak jako nikdy. Od různých variací kosmetických itemů až po nové bloky a zvuky.</p>

									<div className="row">
										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-hat-cowboy-side text-dark"/>
												</h2>
												<div className="ml-3">
													<h5>Čepice, batohy a balónky</h5>
													<p className="text-muted mb-0">Obzvláštni svůj styl desítkami kosmetických itemů, které lze odemknout pomocí herní měny nebo VotePassu.</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="fas fa-cubes text-primary"/>
												</h2>
												<div className="ml-3">
													<h5>Nové bloky</h5>
													<p className="text-muted mb-0">Minecraft jich má dostatek, ale všichni rádi stavíme z dekorativních bloků.</p>
												</div>
											</div>
										</div>

										<div className="col-12">
											<div className="d-flex align-items-center pt-4">
												<h2>
													<i className="far fa-chart-bar text-danger"/>
												</h2>
												<div className="ml-3">
													<h5>Vlastní recepty</h5>
													<p className="text-muted mb-0">Od verze 1.18 upravujeme Minecraft servery ještě více, najdeš tak u nás vlastní ore, armory a zcela upravené některé recepty.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-3 col-md-4 mt-4 pt-2">
							<div
								className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
							<span className="h1 icon2 text-primary">
								<i className="fas fa-book"/>
							</span>
								<div className="card-body p-0 content">
									<h5>Ukrytý příběh</h5>
									<p className="para text-muted mb-0">S lobby se nese i historie serveru, objevíš vše bez jakékoliv pomoci?</p>
								</div>
								<span className="big-icon text-center">
								<i className="fas fa-book"/>
							</span>
							</div>
						</div>

						<div className="col-lg-3 col-md-4 mt-4 pt-2">
							<div
								className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
							<span className="h1 icon2 text-primary">
								<i className="fab fa-discord"/>
							</span>
								<div className="card-body p-0 content">
									<h5>Jedna z největších komunit</h5>
									<p className="para text-muted mb-0">Již přes sedm let jsme partneři Discordu.</p>
								</div>
								<span className="big-icon text-center">
								<i className="fab fa-discord"/>
							</span>
							</div>
						</div>

						<div className="col-lg-3 col-md-4 mt-4 pt-2">
							<div
								className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
							<span className="h1 icon2 text-primary">
								<i className="far fa-smile"/>
							</span>
								<div className="card-body p-0 content">
									<h5>Kosmetické VIP</h5>
									<p className="para text-muted mb-0">Snažíme se, aby VIP nijak nezvýhodňovalo hráče, kteří VIP nemají.</p>
								</div>
								<span className="big-icon text-center">
								<i className="far fa-smile"/>
							</span>
							</div>
						</div>

						<div className="col-lg-3 col-md-4 mt-4 pt-2">
							<div
								className="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
							<span className="h1 icon2 text-primary">
								<i className="fas fa-pizza-slice"/>
							</span>
								<div className="card-body p-0 content">
									<h5>Tuna eventů</h5>
									<p className="para text-muted mb-0">Na našem speciálním Event serveru najdeš tuny eventů pořádané našimi Eventery!</p>
								</div>
								<span className="big-icon text-center">
								<i className="fas fa-pizza-slice"/>
							</span>
							</div>
						</div>

					</div>
				</div>
			</section>
		);
	}

}

export default MainInfoBoxes;
