import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
// @ts-ignore
import { SRLWrapper } from "simple-react-lightbox";

class AboutServer extends Component<any, any> {

	public render() {
		return(
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundImage: `url(https://news.craftmania.cz/content/images/2020/02/image-4.png)`}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> O CraftManii </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Informace o našem serveru a historie</p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item active" aria-current="page">O CraftManii</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section bg-light">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
								<div className="position-relative">
									<img src="https://static.craftmania.cz/logo/rounded_logo.png" className="rounded img-fluid mx-auto d-block w-75"
										alt=""/>
								</div>
							</div>

							<div className="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
								<div className="section-title ml-lg-4">
									<h4 className="title mb-4">Náš příběh</h4>
									<p className="text-muted">Náš server byl založen <span
										className="text-primary font-weight-bold"><a href="https://stats.craftmania.cz/player/MrWakeCZ">MrWakeCZ</a></span> dne <span
										className="text-primary font-weight-bold">21.12.2013 21:12</span> u hostingu Fakaheda.eu
										Tehdy jako malý server pro kamarády s 5 sloty, se nakonec rozšířil až na 20 slotů a o server začal být velký zájem. 
										Každý týden se přidávalo 10 slotů navíc, až se server na podzim v roce 2014 stal největším serverem u Fakahedy a 1. na CzechCraftu.
										Náš server je také znám díky KitPvP, Prisonu, BuildBattle, Gravity, SkyGiants a DrawIt, 
										které CraftMania přivedla na českou Minecraft scénu jako první.
										Dnes již server není největší avšak i po letech, jsme si zachovali kvalitu 
										a dodržení svých pravidel na kvalitní Minecraft server kvůli kterým byl server vytvořen.</p>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* SCREENS */}
				<section className="section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 text-center">
								<div className="section-title mb-4 pb-5">
									<h4 className="title mb-4">Útržky z historie</h4>
									<p className="text-muted para-desc mx-auto mb-0">
										Pár minut, pár dní, pár týdnů a pár let. Čas letí a s ním i historie, 
										zde je pár zásadních momentů z našeho sevrveru. Všechny obrázky jsou z veřejně přístupného Discordu - 
										<a className="text-primary font-weight-bold" href="https://discord.gg/tz46tPx">CraftMania.cz Archive</a>.
									</p>
								</div>
							</div>
						</div>
						<div className="row projects-wrapper" style={{position: "relative", height: '610.15px'}}>
							<SRLWrapper>
								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://news.craftmania.cz/content/images/2020/02/image-4.png" title="">
												<img src="https://news.craftmania.cz/content/images/2020/02/image-4.png" className="img-fluid" alt="Lobby 4.0: Nejnovější lobby, nabízející pohled do historie a vlastní mini příběhy. (2019)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Lobby 4.0</div></h5>
												<h6 className="text-muted tag mb-0">Nejnovější lobby, nabízející pohled do historie a vlastní mini příběhy. (2019)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2018-murder.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2018-murder.png" className="img-fluid" alt="Murder: Jedna z nejslavnějších miniher o vraždění hráčů (2018)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Murder</div></h5>
												<h6 className="text-muted tag mb-0">Jedna z nejslavnějších miniher o vraždění hráčů (2018)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2016-lobby-3-0.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2016-lobby-3-0.png" className="img-fluid" alt="Lobby 3.0: Lobby s velkým portálem, který nikam nevedl! (2016)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Lobby 3.0</div></h5>
												<h6 className="text-muted tag mb-0">Lobby s velkým portálem, který nikam nevedl! (2016)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2016-buildbattle.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2016-buildbattle.png" className="img-fluid" alt="BuildBattle: Nejvíce navštěvovaná minihra, která měla v 7h ráno 900 hráčů. (2016)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">BuildBattle</div></h5>
												<h6 className="text-muted tag mb-0">Nejvíce navštěvovaná minihra, která měla v 7h ráno 900 hráčů. (2016)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2015-prison.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2015-prison.png" className="img-fluid" alt="Prison: Rank Z - Největší a nejslavnější server"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Prison</div></h5>
												<h6 className="text-muted tag mb-0">Důl Z z Prisonu z roku 2015</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '200px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2015-lobby-2.0.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2015-lobby-2.0.png" className="img-fluid" alt="Lobby 2.0: Nejvíce navštětované a také nejvíce diskutované lobby (2015)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Lobby 2.0</div></h5>
												<h6 className="text-muted tag mb-0">Nejvíce navštětované a také nejvíce diskutované lobby (2015)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '400px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2015-bedwars-mushrooms.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2015-bedwars-mushrooms.png" className="img-fluid" alt="BedWars: Mushrooms - Nejhranější mapa na BedWars (2015)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">BedWars: Mushrooms</div></h5>
												<h6 className="text-muted tag mb-0">Nejhranější mapa na BedWars (2015)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '400px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2014-smenarna.png" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2014-smenarna.png" className="img-fluid" alt="Legendární Směnárna: Survival směrnána od Cheapshot (2014)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Legendární Směnárna</div></h5>
												<h6 className="text-muted tag mb-0">Survival směrnána od Cheapshot (2014)</h6>
											</div>
										</div>
									</div>
								</div>

								<div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '400px'}}>
									<div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
										<div className="card-body p-0">
											<a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2014-20-slotu.jpg" title="">
												<img src="https://static.craftmania.cz/images/homepage-about/2014-20-slotu.jpg" className="img-fluid" alt="Hráči, kteří byli na serveru, když CM dosáhla full 20 slotů. (2014)"/>
											</a>
											<div className="content bg-white p-3">
												<h5 className="mb-0"><div className="text-dark title">Elita 20 hráčů na serveru</div></h5>
												<h6 className="text-muted tag mb-0">Hráči, kteří byli na serveru, když CM dosáhla full 20 slotů. (2014)</h6>
											</div>
										</div>
									</div>
								</div>
							</SRLWrapper>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default AboutServer;
