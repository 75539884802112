
export class NumberUtils {

	public static formatNumber(number: number | undefined) {
		if (number === undefined) {
			return 0;
		}
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	public static convertTime(seconds: number, fullTime: boolean = false) {
		let days = Math.floor(seconds / 1440);
		let hours = Math.floor((seconds - (days * 1440)) / 60);
		let minutes = Math.round(seconds % 60);

		if (fullTime) {
			return days + " dní, " + hours + ":" + minutes;
		} else {
			if (days > 0) {
				return (this.formatNumber(days) + " dní");
			} else {
				return (hours + ":" + minutes);
			}
		}
	}
}
