import React from "react";
import { Component } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useClipboard } from 'use-clipboard-copy';
import { useToasts } from 'react-toast-notifications';

const heroImage = require('./imgs/hero.png');
const javaConnect = require('./imgs/java_connect_2.png');

interface HeroTitleState {
	showJavaModal: boolean;
	showBedrockModal: boolean;
}

class HeroTitle extends Component<any, HeroTitleState> {

	public constructor(props: any) {
		super(props);
		this.state = {
			showJavaModal: false,
			showBedrockModal: false
		};
	}

	public JavaConnectButton = () => {
		const clipboard = useClipboard({ copiedTimeout: 1000 });
		const { addToast } = useToasts();
		return (
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 150, hide: 400 }}
				overlay={tooltipJavaVersion}
			>
				<Button className="btn btn-primary mt-2 mr-2 shadow-none" id="java-ip" onClick={() => {
					this.setState({showJavaModal: true});
					clipboard.copy('mc.craftmania.cz');
					addToast('IP Adresa pro Java verzi byla zkopírována do schránky!', { autoDismiss: true, appearance: 'success'});
				}} >Postup jak se připojit</Button>

			</OverlayTrigger>
		);
	}

	public BedrockConnectButton = () => {
		return (
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 150, hide: 400 }}
				overlay={tooltipBedrockVersion}
			>
				<Button className="btn btn-soft-secondary mt-2 mr-2 shadow-none" id="bedrock-ip" onClick={() => {
					this.setState({showBedrockModal: true});
				}}>Minecraft: Bedrock Edition</Button>
			</OverlayTrigger>
		);
	}

	public render() {
		return (
			<section className="bg-half-170 pb-lg-7 pb-md-7 animated-gradient d-table w-100" id="home">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-7 col-md-7">
							<div className="title-heading mt-4">

								{/* Changelogs */}

								<div className="alert alert-dark alert-pills" role="alert">
									<span className="badge bg-success" style={{marginRight: '10px'}}> Poslední oznámení </span>
									<a href="https://craftmania.cz/10-anniversary" target="_blank" rel="noopener noreferrer"><span className="text-white font-weight-bold">CraftMania.cz Anniversary - 10 let!</span></a>
								</div>

								<h1 className="heading text-white mb-3">Český Minecraft server <span
									className="text-primary">CraftMania.cz</span></h1>
								<p className="para-desc text-muted">
									Jsme Minecraft server zaměřující se primárně na Survival servery, minigames a modpacky. Připoj se, dřív než bude pozdě!
								</p>
								<p className="para-desc text-muted">
									Server IP: <code>mc.craftmania.cz</code>
								</p>
								<div className="mt-4">
									<this.JavaConnectButton/>
									<JavaConnectTutorial
										show={this.state.showJavaModal}
										onHide={() => this.setState({showJavaModal: false})}
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 position-relative">
							<img src={heroImage} style={{width: '500px'}} alt=""/>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

const JavaConnectTutorial = (props: any) => {
	const clipboard = useClipboard({ copiedTimeout: 1000 });
	const { addToast } = useToasts();
	return (
		<Modal
			{...props}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Jak se připojit na server
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>
					<h5>1. Zapni Minecraft: Java Edition</h5>
					<div className="alert alert-light" role="alert"> Doporučujeme používat verzi <code>1.20.2</code>. Server podporuje připojení s <code>1.19-1.20.2</code>.</div>
					<div className="alert alert-light" role="alert"> Použít clienty a módy můžeš jakékoliv chceš, které tě nijak nebudou zvýhodňovat nebudou porušovat pravidla serveru. Seznam povolených a zakázaných módů najdeš <a href="https://craftmania.cz/pravidla/povolene-mody/" target="_blank" rel="noopener noreferrer">na našich stránkách.</a>.</div>
					<h5>2. Postup přidání serveru</h5>
					<p>Zvol <code className="font-weight-bold">Multiplayer</code> &gt; <code className="font-weight-bold">Přidat server</code> &gt; Vyplň IP adresu serveru: <code className="text-danger font-weight-bold">mc.craftmania.cz</code></p>
					<h5>3. Další cesta je na tobě!</h5>
					<img style={{maxWidth: '100%'}} alt="" src={javaConnect}/>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button className={"btn btn-info"} onClick={() => {
					clipboard.copy('mc.craftmania.cz');
					addToast('IP Adresa pro Java verzi byla zkopírována do schránky!', { autoDismiss: true, appearance: 'success'});
				}}>{clipboard.copied ? 'IP zkopírována' : 'Znovu zkopírovat IP'}</Button>
				<Button onClick={props.onHide}>Zavřít</Button>
			</Modal.Footer>
		</Modal>
	);
};

const tooltipJavaVersion = (props: any) => (
	<Tooltip id="button-tooltip" {...props}>
		Připojení na server skrz klasický Minecraft v Javě.
	</Tooltip>
);

const tooltipBedrockVersion = (props: any) => (
	<Tooltip id="button-tooltip" {...props}>
		Minecraft: Bedrock Edition (Windows 10, PS4/PS5, Xbox)
	</Tooltip>
);

export default HeroTitle;
