import React, { Component } from "react";
import PageContainer from "../../components/PageContainer";
import { Link } from "react-router-dom";

class GDPRAudit extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundColor: '#0561A5'}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Zásady zpracování osobních údajů </h4>
									<p className="text-white-50 mt-2">Poslední revize: <span className="text-light">3.5.2020</span></p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item"><Link to="/pravidla">Podmínky používání</Link></li>
												<li className="breadcrumb-item active" aria-current="page">Zásady zpracování osobních údajů</li>

											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section bg-light">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="card shadow border-0 rounded">
									<div className="card-body">
										<h3 className="card-title">Provozovatel</h3>
										<p>Provozovatelem portálu <strong className="text-primary">CraftMania.cz</strong> je:</p>
										<ul>
											<li>Josef Král, se sídlem: Ztracená 1126/16, Cheb 350 02, Česká republika.</li>
											<li>Identifikační číslo (IČO): <strong className="text-primary">04881907</strong></li>
										</ul>
										<h3 className="card-title">Právní předpisy</h3>
										<p>Při zpracování osobních údají na našem portálu vždy postupujeme v souladu s následujícími právními předpisy:</p>
										<ul>
											<li>Nařízení Evropského parlamentu a Rady EU 2016/679 ze dne 27. 4. 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů, které vstoupilo v platnost 25. 5. 2018 (dále GDPR).</li>
											<li>Zákon č. 110/2019 Sb., o zpracování osobních údajů ze dne 12. 3. 2019, který vstoupil v platnost dne 24. 4. 2019, nahradil zákon č. 101/2000 Sb. o ochraně osobních údajů.</li>
											<li>Zákon o některých službách informační společnosti č. 480/2004 Sb.</li>
											<li>Občanský zákoník č. 89/2012 Sb.</li>
											<li>Zákon o ochraně spotřebitele č. 634/1992 Sb.</li>
											<li>Případně pak v souladu s dalšími předpisy</li>
										</ul>
										<p>GDPR stanovuje nové požadavky na Správce a Zpracovatele Osobních Údajů a některé současně zpřesňuje. Dále také vymezuje práva Subjektů, jejichž Osobní Údaje jsou zpracovávány.</p>
										<p>Osobní údaje uchováváme a zpracováváme v rámci EU a zároveň přijímáme nezbytné kroky k zabezpečení správného zacházení s osobními údaji a s jejich ukládáním, a to včetně interních postupů, směrnic a přiměřených technologických řešení.</p>
										<h3 className="card-title">Získávání osobních údajů</h3>
										<p>Osobní udaje ukládáme při těchto akcích:</p>
										<ul>
											<li>Při registraci na Forum serveru (https://forum.craftmania.cz)</li>
											<li>Při registraci Minecraft učtu nebo napojení na Minecraft server</li>
											<li>Při nákupu na našem Storu (https://store.craftmania.cz/)</li>
										</ul>
										<h3 className="card-title">Přehledat zpracovávaných osobních údajů</h3>
										<p>Údajě zpracovávané na portálu CraftMania.cz</p>
										<ol>
											<li><strong className="text-primary">Emailová adresa</strong></li>
											<ul>
												<li>Účel: Ukládání za účelem přihlášení se na fórum, identifikace při objednávce na Storu</li>
												<li>Doba: Po dobu existence uživatelského účtu na fórum, 3 roky v případě objednávky na Storu</li>
											</ul>
											<li><strong className="text-primary">IP Adresa</strong></li>
											<ul>
												<li>Účel: Zvýšení bezpečnosti účtu (například odhalení podezřelé aktivity) a bezpečnosti poskytovaných služeb, provádění analýz a statistik.</li>
												<li>Doba: 2 roky</li>
											</ul>
											<li><strong className="text-primary">Nick nebo-li herní jméno</strong></li>
											<ul>
												<li>Účel: Rozeznání uživatele na portálu a Minecraft serveru.</li>
												<li>Doba: Permanentní</li>
											</ul>
											<li><strong className="text-primary">Telefoní číslo</strong></li>
											<ul>
												<li>Účel: Rozeznání uživatele při při vzniku objednávky na Storu, kontrola zakoupených balíčků, identifikace při reklamaci.</li>
												<li>Doba: 3 roky od vytvoření objednávky</li>
											</ul>
											<li><strong className="text-primary">Jméno a příjmení</strong></li>
											<ul>
												<li>Účel: Rozeznání uživatele při při vzniku objednávky na Storu</li>
												<li>Doba: 3 roky od vytvoření objednávky</li>
											</ul>
										</ol>
										<h3 className="card-title">Cookies</h3>
										<p>Cookies jsou malé soubory, které se ukládají do počítače a dovolují aby uživatel byl přihlášený(á) na našem webu neustále, tedy do doby než je uživatel smaže. Cookies mohou také ukládat jiné data ohledně vlastního nastavení na webu.</p>
										<h3 className="card-title">Práva</h3>
										<p>Hráč má kdykoliv právo si vyžádat kopii svých uložených dat. Hráč má také právo na plnou změnu těchto dat pokud nejsou v rozporu s pravidly serveru.</p>
										<p>Hráč může kdykoliv požádat o stažení uložených dat na <strong>support@craftmania.cz</strong> nebo v Ticket systému na Discord serveru CraftMania.cz</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default GDPRAudit;
