import React from 'react';
import { Component } from "react";
import moment from 'moment';
import 'moment/locale/cs';

interface NewsItemProps {
	title: string;
	description: string;
	url: string;
	image: string;
	tag: string;
	tag_url: string;
	time: string;
	writer: string;
}

class NewsItem extends Component<NewsItemProps, any> {

	public render() {
		return (
			<div className="col-lg-4 col-md-6 mt-4 pt-2">
				<div className="card blog rounded border-0 shadow">
					<div className="position-relative">
						<img src={this.props.image} className="card-img-top rounded-top" alt="..."/>
						<div className="overlay rounded-top bg-dark"/>
					</div>
					<div className="card-body content">
						<small><a href={this.props.tag_url} target="_blank" rel="noreferrer noopener" className="text-primary h6">{this.props.tag}</a></small>
						<h5><a href={this.props.url} target="_blank" rel="noreferrer noopener" className="card-title title text-dark">{this.props.title}</a></h5>
						<p className="text-muted">{this.props.description}</p>
						<div className="post-meta d-flex justify-content-between mt-3">
							<a href={this.props.url} target="_blank" rel="noreferrer noopener" className="text-muted readmore">Zjistit více <i className="fas fa-angle-right"/></a>
						</div>
					</div>
					<div className="author">
						<small className="text-light user d-block"><i className="mdi mdi-account"/> {this.props.writer}</small>
						<small className="text-light date"><i className="mdi mdi-calendar-check"/> {moment(this.props.time).format("LLL")}</small>
					</div>
				</div>
			</div>
		);
	}
}

export default NewsItem;
