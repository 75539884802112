import React from 'react';
import { Component } from "react";
import { fetchNewsPost } from "../../utils/FetchUtils";
import { GhostAPIPost } from "../../interfaces/GhostAPI";
import NewsItem from "./NewsItem";

interface LatestNewsState {
	newsPosts: GhostAPIPost[] | null;
}

class LatestNews extends Component<any, LatestNewsState> {

	public constructor(props: any) {
		super(props);
		this.state = {
			newsPosts: null
		};
	}

	public async componentDidMount() {
		const newsPosts = await fetchNewsPost();
		this.setState({
			newsPosts
		});
	}

	public render() {
		if (this.state.newsPosts === null || undefined) {
			return null;
		}
		return(
			<section className="section bg-light">
				<div className="container">

				<div className="row justify-content-center">
					<div className="col-12 text-center">
						<div className="section-title mb-4 pb-2">
							<h4 className="title mb-4">Poslední novinky</h4>
							<p className="text-muted para-desc mx-auto mb-0">Na serveru se stále něco děje, všechny novinky najdeš na <a href="https://news.craftmania.cz/"><span className="text-primary font-weight-bold">news.craftmania.cz</span></a><br/> Zde je pár posledních novinek a změn!</p>
						</div>
					</div>
				</div>

				<div className="row">
					{this.generateNews(this.state.newsPosts)}
				</div>

				</div>
			</section>
		);
	}

	private generateNews(array: GhostAPIPost[]) {
		let showArray: JSX.Element[] = [];
		for (let i = 0; i < 3; i++) {
			let item: GhostAPIPost = array[i];
			showArray.push(
				<NewsItem
					key={i}
					title={item.title}
					description={item.custom_excerpt}
					url={item.url}
					image={item.feature_image}
					time={item.published_at}
					tag={item.primary_tag.name}
					tag_url={item.primary_tag.url}
					writer={item.primary_author.name}
				/>
			);
		}
		return showArray;
	}

}

export default LatestNews;
