import React, { Component } from "react";
import PageContainer from "../../components/PageContainer";
import { Link } from "react-router-dom";

class AllowedMods extends Component<any, any> {

	public render() {
		return(
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundColor: '#01A68A'}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Povolené a zakázané módy </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Tento seznam obsahuje všechny povolené ale i zakázané módy.</p>
									<p className="text-white-50 mt-2">Poslední revize: <span className="text-light">1.12.2021</span></p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item"><Link to="/pravidla">Podmínky používání</Link></li>
												<li className="breadcrumb-item active" aria-current="page">Povolené a zakázané módy</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section bg-light">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="card shadow border-0 rounded">
									<div className="card-body">
										<p>Tento seznam zahrnuje názvy módů, launcherů, které jsou/nejsou povolené. 
											Žádné vyjímky se po zjištění dělat nebudou, pokud tedy u tebe zjistíme jeden z těchto nepovolených módů budeš potrestán podle pravidel.
										</p>
										<h3 className="card-title">Povolené módy</h3>
										<ul>
											<li>Lunar Client, Optifine, Labymod, Badlion, 5zig</li>
											<li>Minimapy (kompletně povoleno i s hráči)</li>
											<li>Damage Indicators</li>
											<li>Better Sprinting</li>
											<li>Toggle Sneak</li>
											<li>Shaders</li>
											<li>Too Many Items & Not Enough Items</li>
											<li>Status Effects</li>
											<li>Armor Status</li>
											<li>Mouse Tweaks</li>
											<li>Better PvP</li>
											<li>Macros (není povoleno v PvP a se souboji s moby)</li>
											<li>Inventory Tweaks (není povoleno v PvP)</li>
											<li>Tabby Chat</li>
											<li>Fastcraft</li>
											<li>Chat Companions</li>
											<li>Gamma</li>
											<li>Brightness</li>
											<li>Keystrokes Mod</li>
											<li>Schematica Printer</li>
										</ul>
										<h3 className="card-title">Zakázané módy</h3>
										<ul>
											<li>Cheat Clients (Wurst, Huzuni, Reflex, atd.)</li>
											<li>AutoClicker (zahrnuje programy na klikání)</li>
											<li>Nuker</li>
											<li>X-Ray (zahrnuje X-ray Texture Pack!)</li>
											<li>Scenters</li>
											<li>Forcefield</li>
											<li>Smart Moving</li>
											<li>Toggle Jump</li>
											<li>Player ESP</li>
											<li>Tracers</li>
											<li>Autoswitch</li>
											<li>Ghost Clients</li>
											<li>Fly & Fly-Hack</li>
											<li>Cave Finder</li>
											<li>Cheating Essentials</li>
											<li>Chest Finder, Chest ESP</li>
											<li>KillAura</li>
											<li>BoatFly</li>
											<li>Auto Fish</li>
											<li>WorldDownloader</li>
											<li>Derp</li>
											<li>Baritone</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default AllowedMods;
