import React from 'react';
import { Component } from "react";
import { fetchServerPlayers, fetchServerStats } from "../../utils/FetchUtils";
import { NumberUtils } from "../../utils/NumberUtils";

interface ServerStatsState {
	registeredPlayers: number | null;
	onlinePlayers: number | null;
	playedTime: number | null;
	completedQuests: number | null;
}

class ServerStats extends Component<any, ServerStatsState> {

	public constructor(props: any) {
		super(props);
		this.state = {
			registeredPlayers: null,
			onlinePlayers: null,
			playedTime: null,
			completedQuests: null
		};
	}

	public async componentDidMount() {
		const serverStats = await fetchServerStats();
		const serverPlayers = await fetchServerPlayers();
		this.setState({
			registeredPlayers: serverStats.totalPlayers,
			playedTime: serverStats.totalPlayTime,
			completedQuests: serverStats.totalCompletedQuests,
			onlinePlayers: serverPlayers.players.online
		});
	}

	public render() {
		return (
			<section className="section pt-0" style={{padding: '40px 0'}}>
				<div className="container">

					<div className="row pt-2 position-relative" id="counter" style={{zIndex: 1}}>
						<div className="col-md-3 col-6 mt-4 pt-2">
							<div className="counter-box text-center">
								<i className="fas fa-users text-blue" style={{fontSize: '48px'}}/>
									<h2 className="mb-0 mt-4">
										<span className="counter-value" data-count={this.state.registeredPlayers !== null ? this.state.registeredPlayers : 0}>
											{this.state.registeredPlayers !== null ? NumberUtils.formatNumber(this.state.registeredPlayers) : '...'}
										</span>
									</h2>
									<h6 className="counter-head text-muted">Registrováno hráčů</h6>
							</div>
						</div>

						<div className="col-md-3 col-6 mt-4 pt-2">
							<div className="counter-box text-center">
								<i className="fas fa-user-astronaut text-blue" style={{fontSize: '48px'}}/>
								<h2 className="mb-0 mt-4">
									<span className="counter-value" data-count={this.state.onlinePlayers !== null ? this.state.onlinePlayers : 0}>
										{this.state.onlinePlayers !== null ? this.state.onlinePlayers : '...'}
									</span>
								</h2>
								<h6 className="counter-head text-muted">Online hráčů</h6>
							</div>
						</div>

						<div className="col-md-3 col-6 mt-4 pt-2">
							<div className="counter-box text-center">
								<i className="fas fa-clock text-blue" style={{fontSize: '48px'}}/>
									<h2 className="mb-0 mt-4">
										<span className="counter-value" data-count={this.state.playedTime !== null ? this.state.playedTime : 0}>
											{this.state.playedTime !== null ? NumberUtils.convertTime(this.state.playedTime) : '...'}
										</span>
									</h2>
									<h6 className="counter-head text-muted">Celkově odehraný čas</h6>
							</div>
						</div>

						<div className="col-md-3 col-6 mt-4 pt-2">
							<div className="counter-box text-center">
								<i className="fas fa-award text-blue" style={{fontSize: '48px'}}/>
									<h2 className="mb-0 mt-4">
										<span className="counter-value" data-count={this.state.completedQuests !== null ? this.state.completedQuests : 0}>
											{this.state.completedQuests !== null ? NumberUtils.formatNumber(this.state.completedQuests) : '...'}
										</span>
									</h2>
									<h6 className="counter-head text-muted">Splněno questů</h6>
							</div>
						</div>
					</div>
					<div className="feature-posts-placeholder bg-light" style={{height: '100%'}}/>
				</div>
			</section>
		);
	}
}

export default ServerStats;
