import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ToastProvider } from 'react-toast-notifications';
import MainRouter from "./MainRouter";
// @ts-ignore
import SimpleReactLightbox from "simple-react-lightbox";
import { withRouter } from './utils/withRouter';

function App() {
	return (
		<React.Fragment>
			<ToastProvider placement="top-right">
				<SimpleReactLightbox>
					<MainRouter/>
				</SimpleReactLightbox>
			</ToastProvider>
		</React.Fragment>
	);
}

export default withRouter(App);
