import { Component } from "react";
import React from "react";
import { Link } from "react-router-dom";

const enderman = require('../images/enderman_error.png');

class NotFound extends Component<any, any> {
	public render() {
		return (
			<div>
				<section className="bg-home d-flex align-items-center">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-8 col-md-12 text-center">
								<img src={enderman} className="img-fluid" alt=""/>
									<div className="text-dark mb-4 mt-4 error-page">Zadaná stránka neexistuje.</div>
									<p className="text-muted para-desc mx-auto">
										No, někdy se to stane buď naší nebo tvojí chybou, ale je na to jednoduché řešení! Stačí kliknout na tlačítko níže.
									</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 text-center">
								<Link to="/" className="btn btn-primary mt-4 ml-2">Přejít domů</Link>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default NotFound;
