import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";

class ContactPage extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundColor: '#3d775b'}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Kontakt </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Odkazy, kde nás můžeš jednoduše kontaktovat s jakýmkoliv problémem.</p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item active" aria-current="page">Kontakt</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container mt-60">
						<div className="row">
							<div className="col-md-4">
								<div className="card border-0 text-center features feature-primary feature-clean">
									<span className="h1 icon2 text-primary">
										<i className="fab fa-discord"/>
									</span>
									<div className="content mt-2">
										<h5 className="fw-bold">Discord <span className="badge bg-soft-dark text-white"> Primární</span></h5>
										<p className="text-muted">Spoj se s námi pomocí našeho Discord serveru. Vytvoř si ticket v kanálu <strong>#vytvoreni_ticketu</strong></p>
										<a href="https://discord.gg/craftmania" className="read-more">discord.gg/craftmania</a>
									</div>
								</div>
							</div>

							<div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
								<div className="card border-0 text-center features feature-primary feature-clean">
									<span className="h1 icon2 text-primary">
										<i className="fas fa-at"/>
									</span>
									<div className="content mt-2">
										<h5 className="fw-bold">Email</h5>
										<p className="text-muted">Kontaktuj nás kdykoliv na emailu s jakoukoliv žádostí či prosbou.</p>
										<a href="mailto:support@craftmania.cz"
											className="read-more">support@craftmania.cz</a>
									</div>
								</div>
							</div>

							<div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
								<div className="card border-0 text-center features feature-primary feature-clean">
									<span className="h1 icon2 text-primary">
										<i className="fab fa-instagram"/>
									</span>
									<div className="content mt-2">
										<h5 className="fw-bold">Instagram</h5>
										<p className="text-muted">Napiš nám na naší Instagram stránce do zpráv!</p>
										<a href="https://www.instagram.com/craftmania.cz/" className="read-more">@craftmania.cz</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default ContactPage;
