import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";
// @ts-ignore
import { SRLWrapper } from "simple-react-lightbox";

class TenYearsAniversary extends Component<any, any> {

	public render() {
		return(
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundImage: `url(https://static.craftmania.cz/legacy-screens/lobby-2019-3.png)`}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> CraftMania: Anniversary </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Výroční téma uveřejněné dne 14.1.2024, kdy CraftMania.cz oficiálně existovala deset let.</p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item active" aria-current="page">CraftMania: Anniversary</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/* SCREENS */}
				<section className="section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12">
								<div className="section-title mb-4 pb-5">
                                    <h4 className="title mb-4">Úvod</h4>
									<p className="text-muted mx-auto mb-0">
                                        Tak, a je to tu zase! Výroční téma, po několika letech se vrací zpět, popravdě poslední výroční téma vyšlo <span className="text-primary font-weight-bold">naposledy v roce 2020</span>. Kdy se jednalo o shrnutí covidového roku a všech změn, co se za ten rok událo. Ovšem dnes tu jsme kvůli něčemu jinému, dnes slaví CraftMania oficiálně 10 let od svého uveřejnění. 
									</p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Dříve tyto témata vycházely 21.12. ovšem, později mi došlo, že vlastně to není pravda. Dne 21.12. byl nákup serverů, ne uveřejnění serverů, a tak se to letos posunulo na 14.1., tedy na dnešek!
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Jenže, kde začít, přece jenom deset let není krátká doba, a moje paměť už také moc neslouží. Né že bych byl starý, to ne ale né vše si již pamatuji, tak přesně jako třeba před pěti lety. Ovšem na drtivou většinu událostí, změn a zážitků se nedá nikdy zapomenout. O tom bude toto téma! Společně si projdeme posledních deset let CraftManie, připomeneme si společně, co vše jsme dokázali a pak si odpovíme na jednu z největších otázek za poslední dva roky, co bude dál…
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-secondary font-weight-bold">Takže přetáčíme čas… zpět do roku 2013!</span>
                                    </p><br/>
                                    <h4 className="title mb-4">2013</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        CraftMania server byl poprvé spuštěn <span className="text-primary font-weight-bold">21.12.2013 ve 21:12</span>. Proč ale? Této události předházelo několik událostí, které zde nebudu rozepisovat, jelikož o nich bude zmíněno v knize, avšak pro naše účely stačí abychom věděli, že server byl vytvořen pro kamarády za Facebooku. V podstatě to vše začalo s Čarovným Minecraftem od Gejmra. Ano, čtete správně, tak jsem se dostal k Minecraftu ovšem bylo to až na podzim roku 2013. Již téhož roku v listopadu, jsem si hrál s modovanými servery a IC2, do dnes jedním z nejlegendárnějích módů. Tím, že jsem hledal kde spustit takové servery jsem tehdy narazil na jeden z největších herních hostingů u nás - <span className="text-success font-weight-bold">Fakaheda.eu</span>.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A zde vše začalo, Fakaheda tehdy měla dalo by se říct velký monopol na Minecraft servery, prakticky všechny velké nemodované servery kromě Survival-Games zde byly spuštěny, a tak jsem narazil tehdy na jeden z největších serverů té doby <span className="text-primary font-weight-bold">CrazyGames.cz</span>, kde jsem tež začal hrát, konkrétněji na Skyblocku. Ovšem narazil jsem na to, že mě to ani tak nebavilo, a tak jsem se začal šťourat v tom, jak spustit takový server ještě víc, a tak jsme došli až k 21.12.2013, kdy jsem spustil první server, tehdy ješte bez jména a oznámil jsem to na Facebooku v chatu.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Ovšem zde je jedna <span className="text-primary font-weight-bold">velká historka</span>, která se tehdy stala. Já na serveru nebyl první, byl tam hráč a stál v přírodě. Do dnes si ten pohled pamatuji, jelikož to pro mě tehdy byl obrovský šok, jak se tam dostal (servery v základu nemají zapnutý whitelist, a tím že byl zalistovaný na Fakahedě jako nový..). Nepamatuji si bohužel jméno tohoto hráče, ale ptal se na to.. <span className="text-secondary font-weight-bold">“Budou někdy na tomto serveru minihry?”</span>. Odmítl elaborovat a odpojil se. Až roky zpět mi vlastně pak došlo, jestli to nebylo nějaký zanamení, náhoda? Nevím… tehdy jsem to nijak neřešil.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Jelikož byl čas před Vánoci, tak co dělat.. začal jsem stavět spawn. Tehdy také vznikl základ všech spawnů, kterých se držela CraftMania roky. Na spawnu byla kovadlina, spawnery, portály, informační cedule dokonce i shop (jelikož Minecraft v 1.7.2 toho moc neměl, že?!). Do dnes existují screenshoty toho spawnu, a popravdě jsem na něj hrdý, že i zde leží jeden z největších základů.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Bohužel o server nebyl nijak velký zájem</span> (těmi kamarády), a tak 14.1.2014 došlo k tomu, že jsem zakoupil doménu CraftMania.cz. Je nutno ovšem podotknout, že název jsem já nevymyslel, název byl právě vytvořen kamarády z Facebooku.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A poté vše začalo…
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '230px'}}>
                                        <SRLWrapper>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/legacy_lobby_1.JPG" className="img-fluid" alt="Pohled na jediné lobby, které kdy Wake postavil."/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Pohled na jediné lobby, které kdy Wake postavil.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                            <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                <div className="card-body p-0">
                                                    <a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2018-murder.png" title="">
                                                        <img src="https://static.craftmania.cz/legacy-screens/legacy_lobby_2.JPG" className="img-fluid" alt="Pohled na jediné lobby, které kdy Wake postavil."/>
                                                    </a>
                                                    <div className="content bg-white p-3">
                                                        <h6 className="text-muted tag mb-0">Pohled na jediné lobby, které kdy Wake postavil.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="https://static.craftmania.cz/images/homepage-about/2016-lobby-3-0.png" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/legacy_lobby_3.JPG" className="img-fluid" alt="Pohled na jediné lobby, které kdy Wake postavil."/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Pohled na jediné lobby, které kdy Wake postavil.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SRLWrapper>
                                    </div>
                                    <h4 className="title mb-4">2014</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Tím, že CraftMania byla u Fakahedy, byla tedy automaticky zalistována měla automaticky “reklamu” na jednom z tehdy hodně doporučovaných server listů. A tak to šlo rapidně nahoru, <span className="text-primary font-weight-bold">během prvního měsíce hrálo na CraftManii okolo 20 hráčů</span> v peaku, a server byl tak plný. Postupně se každých 7-14 dní přidávalo vždy 10 slotů, změnil se i spawn aby byl větší, což tehdy takový spawn nikdo neměl a tvořil strašně majestátně.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A na server přišla i jedna osoba která později figurovala jako jeden z nejdůležitějších lidí CM. Jeho jméno je, a mnoho hráčů ho zná <span className="text-danger font-weight-bold">Cheapshot</span>, dnes již <span className="text-danger font-weight-bold">Cheap_</span>. Zde se váže historka, kterou jsem již psal několikrát, já původně chtěl Cheapovi dát ban, jelikož obcházel hlasování na Czech-Craftu a server pak měl díky tomu na své poměry hodně hlasů. Otázka je, co by se stalo kdybych mu ten ban nechal, že?
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tehdy také vznikla jedna z nejvíc legendárních stránek na serveru, bylo to forum. CraftMania totiž jela jiný přístup než jiné servery. Všude jste museli jít do voice nebo psát maily a vyžadovat unban. My jsme měli forum, tehdy ještě hodně legacy na kterém později Admin Team řešil unbany a hráči své problémy. Byl to naprosto jiný přístup, který byl základem k tomu, že CraftMania forum v pozdějích letech jedno z největších forum v česku a slovensku.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tehdy také proběhl <span className="text-primary font-weight-bold">první nábor</span>, kdy se nabrali první členové AT - Cheap_, ghe5, zuzik92, sCuess, Firefighter95?, Menclik12, xero2000 a jiný… do dnes některé najdete na Discordu.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tito lidé a hráči položili stabilní základ CM, a postupně jsme vylepšili server tak, že Vánoce 2014 jsme slavili už s proxy serverem a 300 hráči na serveru. Docela velký start z 5 slotů a nikde nikdo, na narvaný server s 300+ hráči - Survival, Skyblock a SkyWars.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A aby toho nebylo málo, <span className="text-primary font-weight-bold">CraftMania tehdy již byla v TOP 10. na Czech-Craftu</span>, což znamenalo tehdy opravdu hodně.
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '420px'}}>
                                        <SRLWrapper>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/wake-cheap-ghe5.png" className="img-fluid" alt="Wake, Cheapshot, Ghe5"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Wake, Cheapshot, Ghe5</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/wake-smenarna.png" className="img-fluid" alt="Wake ve směnárně, kde se denně stály fronty!"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Wake ve směnárně, kde se denně stály fronty!</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/2014-listopad.png" className="img-fluid" alt="Fotka z lobby s hráči, na levé straně u Wejka je Menclik12!"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Fotka z lobby s hráči, na levé straně u Wejka je Menclik12!</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/30-3-2014.PNG" className="img-fluid" alt="Fakaheda seznam serverů: 30.3.2014"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Fakaheda seznam serverů: 30.3.2014</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/2-11-2014.PNG" className="img-fluid" alt="Fakaheda seznam serverů: 2.11.2014"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Fakaheda seznam serverů: 2.11.2014</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SRLWrapper>
                                    </div>
                                    <h4 className="title mb-4">2015</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        A tak vstupujeme do jednoho z největších roků, který změnil i český Minecraft tak jak si jej pamatujeme. <span className="text-primary font-weight-bold">V roce 2015 se toho stalo tolik</span>, že se bojím že ani v knize, kterou píšu to nebude vše. Rok 2015 byl jak revolučním, nadčasovým tak i extremě obrovským ve všem, na co pomyslíte.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Rok 2015 jsme na CraftManii odpálili <span className="text-warning font-weight-bold">kompletně novým fórum</span>, tak jak jej znáte do dnes postavený na aplikaci Xenforo spravovaný KopiisCZ a mnou. Ve všech ohledech to byl tehdy tak velký krok, že by vystačil na tři klasické kroky…
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        S tím vším přišel v ten samý měsíc ještě jedna velká novinka, <span className="text-secondary font-weight-bold">CraftMania nové lobby vytvořený Ccarmel a SpiritV</span>. Nepamatujete si, který to bylo? To po vzoru HiveMC, který teasovalo na minihry, Prison a jiné. Jednalo se o první lobby, které bylo postavené přímo CM AT, a tak v sobě mělo tunu ikonických detailů, bylo tak perfektní, že monoho hráčů tehdy psalo, že jsme jej stáhli… bohužel SpiritV s Ccarmel na něm strávili několik měsíců, aby bylo ušito namíru CM.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        S novým lobby také na český Minecraft přišlo něco, co nikdo neměl tedy spíš na CraftManii. <span className="text-primary font-weight-bold">Autologin pro originálky</span>. Ano v roce 2015 jako první server mezi všemi jsme uvedli AutoLogin, tedy automatické přihlašování pro originální hráče. Automaticky se tato funkce již tehdy stala jednou z nejvíc vyzdvihovanou věcí na serveru, jelikož fungovala tak šikovně, že to všichni milovali. Od toho se také odvjíel pozdějíší přístup, kdy CM hrálo pravděpodobně kvůli tomu i “nejvíce” originálních hráčů než na jiných serverech. Ostatním serverům trvalo roky, než dokázali zavést AutoLogin, a nekteré servery to ani nemají do dnes.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Jak jsem psal, v roce 2015 se toho stalo tolik, že to nelze ani vypsat ovšem je tu ještě pár věcí, co bych rád zmínil a všichni jej známe...
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Prison 1.0</span>! Jeden z nejvíc legendárních serverů připisovaných CraftManii. Proč? Tehdy vznikl takový nešvar, jelikož MenT měl vydat svůj Prison server který se ovšem naprosto lišil od toho CM. Hráči ovšem mysleli, že se jedná o MenTův Prison, který bude na CM, nebo o jeho kopii. Trvalo tehdy dost měsíců, než všem došlo, že to je něco jiného… avšak každý to miloval. S verzí MC 1.8 na Prisonu bylo denně narváno - 200 hráčů. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        V tu dobu ovšem šlo vše nahoru, CraftMania měla dva servery <span className="text-primary font-weight-bold">Annihilation</span>, které byly tež dost narvány, <span className="text-primary font-weight-bold">SkyWars</span> s portálem uprostřed - tento server byl konstantně tež nacpaný. Nový Creative server, který byl tak žádaný že nakonec vznikly dva a oba byly narvaný k prasknutí.. oba dohromady měly denně 400 hráčů. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        CraftMania tehdy strašně letěla nahoru, bylo to nekontrolovatelné a nešlo to do hlavy ani Fakahedě. Kde si mysleli, že něco slavíme a já jim odepisoval, že ne že to je normální den. Do dnes si pamatuji že jeden víkend bylo 600 hráčů a ten další už 680 v neděli, o týden později 750.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        S počtem hráčů ovšem roste i nenávist hráčů. Většina hráčů nedokáže uznat svoje chyby a tak jediné, co by mohli zvládnout je ti uškodit. Jak by ti tehdy mohl hráč uškodit? No pošle ti botíky na server. Z toho důvodu v roce 2015 a 2016 bylo zaznamenáno nejvíce výpadků v celé historii serveru, jenom za rok 2015 to je okolo 150. Díky tomu zde ovšem byly položeny jiné základy…
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tento problém se neřešil jenom u CraftManie ale byl to problém napříč všemy servery. A tak jsem poznal Gejmra (Survival-Games) a Heaxeluise (ShotWars - následovník CrazyGames). Paralerně jsem s nimi řešil jak tyto problemy vyřešit a trvalo měsíce někdy i roky, než jsme nad tím vším vyzráli.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Paradoxně si z té doby skoro nic nepamatuji, nevím proč.. bud toho bylo moc, nebo … nevím. Ovšem existuje historie, že? Tak co vše představila CraftMania v roce 2015? Když byl tak revoluční?
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Nové forum, nové lobby, Autologin, BedWars 1.0, Creative, Prison, Ultra Hardcore, DrawIt, CraftMania Comunity (statistiky), CraftCoins, kompletně první v CZ/SK Store s PSC, PayPal atd., a stovky menších věcí… a eventů, událostí. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Nádherný rok, že?</span> Ještě když vyšla jedna z nejvíc legendárních verzí Minecraftu 1.8!
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        O Vánocích na CraftManii bylo v peaku 1300 hráčů.
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '500px'}}>
                                        <SRLWrapper>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/xenforo-web.png" className="img-fluid" alt="Nový web pro rok 2015!"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Nový web pro rok 2015!</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/drawit.png" className="img-fluid" alt="DrawIt"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">DrawIt</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/bedwars.png" className="img-fluid" alt="BedWars 1.0 - Mushrooms (nejvíc oblíbená mapa)"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">BedWars 1.0 - Mushrooms (nejvíc oblíbená mapa)</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/lobby_hw.png" className="img-fluid" alt="Nový lobby po vzoru HiveMC"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Nový lobby po vzoru HiveMC</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/prison-z.png" className="img-fluid" alt="Prison: Mine Z"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Prison: Mine Z</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/craftbox.png" className="img-fluid" alt="CraftBox na lobby, ze kterého padaly cosmetics za CraftCoins"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">CraftBox na lobby, ze kterého padaly cosmetics za CraftCoins</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '400px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/vanoce-2015.png" className="img-fluid" alt="Počet hráčů na Vánoce 2015"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Počet hráčů na Vánoce 2015</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SRLWrapper>
                                    </div>
                                    <h4 className="title mb-4">2016</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Do roku 2016 CraftMania vstoupila tak jak nikdy, <span className="text-primary font-weight-bold">opět s rekordem hráčů</span>. A tak se také stalo, co se tehdy mělo stát, CraftMania v lednu 2016 překonala největší server v česku v počtu hráčů Survival Games. Né jednou ale několikrát, dalo by se tak říct, že CM a SG měli tehdy stejně hráčů v peaku. Když si vezmete tu sílu CM, která nebyla nijak propagovaná youtubery, žádný streameři nic. Pouze jsme drželi základ, kvalitu a novinky, které hráči chtěli. O SG se to nikdy nedalo říct, že… CraftMania tehdy měla staršnou sílu, od nejlepších Survival serverů po přelomový minihry jako BedWars, Skywars a jiné.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-secondary font-weight-bold">Některé dny CraftMania překonávala SurvivalGames až o 200 hráčů, tedy 1700 vs 1500.</span>
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tehdy ovšem v lednu vyšlo něco, co změnilo naprosto Minecraft na budoucích deset let. Něco, co nikdo nechtěl - <span className="text-danger font-weight-bold">Minecraft 1.9</span>. Den 29.2.2016 se stal pro CraftManii osudovým zlomem, kdy vše začalo jít tam kam nemá. Server odkázaný na komunitu, která milovala Minecraft a chtěla updaty, začal rozporovat s tím, co všichni chtěli.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Z mého pohledu jsem nikdy nepochopil, proč Mojang udělal tuto změnu o kterou nikdo nežádal. Vlastně tím i Mojang nastínil budoucnost Minecraftu, a jejich neschopnost vyvíjet vlastní hru. Asi si říkáte, proč usuzuji, že za updadek CM může 1.9? Nikdo to nechtěl, nikdo o to nežádal… všechny verze nad 1.9 bylo nejvíce odpuzované hráči, <span className="text-primary font-weight-bold">CM tehdy byla označována za odpad jenom kvůli 1.9 serverům</span> i přes to že jsme 1:1 všude implikovali starý PvP. Psychika hráčů s tím, že tam je nový Minecraft dělala hodně.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A tím, že jsem psal, že Mojang tím nastínil že je uplně mimo završil, že v roce 2016 vydal svůj slavný update EULY. Který naprosto zdemoloval všechen zbytek, který jsme měli ve vedení serverů. Když Vám někdo začne diktovat do toho, že nesmíte prodávat nic kromě cosmetics, a začne si to enforcovat i zpětně, a ani ti neodpoví na maily, proč nebo kde některý servery udělali chybu… byla to katastrofa.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Od února 2016 již CraftMania <span className="text-primary font-weight-bold">nikdy nepřekonala</span> Survival Games, a tak vše šlo ke dnu pomalu a jistě. I přes to ovšem v tomto roce vyšlo mnoho legendárních updatů, na které je dobré si vzpomenout.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Jedním z nich je třeba <span className="text-primary font-weight-bold">BuildBattle</span>, který po oznámení způsobil tak velký poprask, že v osm hodin ráno byly všechny lobby na CraftManii plné a na server se nedalo připojit, protože asi 700 hráčů, čekalo na to až se uvolní hry aby si mohli zahrát. Tehdy cedulkové připojování, které s každým kliknutím propinglo daný server, neskutečně ddosovalo servery Fakahedy, která později z toho měla velké problémy.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tež vyšly TurfWars v roce 2016, SkyGiants, Vanilla na 1.9, Annihilation 3.0, který plně dokázalo využít novinek v MC 1.9, legendrání Frakce, SpeedBuilders, Teggit a ze všeho největší SkyWars 4.0 které byly rozděleny na Solo, Team, Insane.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Ještě ale jedna důležitá věc se stala v 2016, založili jsme <span className="text-primary font-weight-bold">oficiální Discord CraftMania.cz</span> a stali jsme se partneři Discordu. Na můj truc, že TeamSpeak je na hovno jsme nutili všechny hráče používat Discord, že to je budoucnost, měl jsem pravdu? Partner mi přežil do dnes, dnes už ani nejsou…
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        O Vánocích 2016 bylo na CraftManii v peaku 900 hráčů.
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '450px'}}>
                                        <SRLWrapper>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/build-battle-lobby.png" className="img-fluid" alt="Uveřejnění BuildBattle v 8:00 ráno."/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Uveřejnění BuildBattle v 8:00 ráno.</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/factions-generation.png" className="img-fluid" alt="Nová generace světa na tehdejších Frakcích"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Nová generace světa na tehdejších Frakcích</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/lobby_2016-1.png" className="img-fluid" alt="Nové lobby 2016!"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">Nové hlavní lobby 2016!</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/lobby_2016-2.png" className="img-fluid" alt="MiniGames lobby pro Annihilation"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">MiniGames lobby pro Annihilation</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
                                                <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                    <div className="card-body p-0">
                                                        <a className="mfp-image d-inline-block" href="/" title="">
                                                            <img src="https://static.craftmania.cz/legacy-screens/lobby_2016-3.png" className="img-fluid" alt="MiniGames lobby pro BedWars"/>
                                                        </a>
                                                        <div className="content bg-white p-3">
                                                            <h6 className="text-muted tag mb-0">MiniGames lobby pro BedWars</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SRLWrapper>
                                    </div>
                                    <h4 className="title mb-4">2017</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Rok 2017 se nesl ve znamení <span className="text-primary font-weight-bold">řešení problémů s Minecraftem</span>, EULOU, hledání řešení a problémů. Ovšem tyhle problémy nebyly jenom na CraftManii ale i v mém osobním životě. Klepala mi na záda maturita, a tak CraftMania musela jít stranou. Byla to strašně divná doba, bylo to jako kdybys rozjel vlak a nevěděl jak ho řídit a on sám automaticky brzdil. Tak bych to přirovnal k CraftManii.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        V tomto roce, také bylo několik dotazníků abychom jasně dokázali odhadnout, co chce komunita a jak má server pokračovat, přes veškeré kroky se nic nedařilo. A nedařilo se ani u mě v osobním životě, kde na mě byly kladeny obrovské nároky, které se začaly projevovat i na CraftManii.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Vydali jsme i pár nových her jako GetDown, <span className="text-success font-weight-bold">Vanilla Skyblock</span> (později označovaný jako SkyCloud), Friends s Clany, tunu Build Eventů, Team BuildBattle, a také poprvé Vánoční Minigames.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tento rok bude více popsán v knize, jelikož se zde na pozadí dělo hooodně věcí hlavně v mém osobním životě, ale také to je rok, kdy jsem se stal peněžně závislý na CraftManii. Jelikož kvůli neshodám doma, jsem se rozhodl vybrat všechny peníze CM a zaplatit si z toho školu, a ubytování na ubytovně v Praze. Spravoval jsem pak ke konci roku CM na kolenou, s mobilním připojením s 2 tisíci na měsíc.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        I přes to vše, ke konci roku na CraftManii hrálo 600 hráčů v peaku.
                                    </p><br/>

                                    <h4 className="title mb-4">2018</h4>
                                    <p className="text-muted mx-auto mb-0">
                                    <span className="text-danger font-weight-bold">A jsme v roce 2018, v roce, který pohřbil vše. CraftManii, mě, budoucnost i minulost.</span> Zde se také přestaly psát novoroční souhrny, jelikož jsem neviděl nic v tom, něco sepsat když jsem s tím nebyl já sám spokojený.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Na základě dotazníků z roku 2017, jsme tedy pokračovali ve vedení serveru, tak jak si hráči přáli a jak jsme uznali za vhodné. Šel jsem do roku 2018 s tím, že bude lepší než 2017, opak je ale pravdou. Kvůli úpadkům nákupů VIP byla CraftMania nucená řešit problém s nedostatkem peněz a tak se zavedlo <span className="text-primary font-weight-bold">Hero VIP</span>. Takhle, teď lžu. CraftMania měla vždy dostatek peněz pro sebe, ale ne už pro mě a můj pobyt v Praze. Do dnes si pamatuji jak jsem šel po Palmovce, s pizzou za 50kč co jsem měl na ten den, na ubytovnu, abych vyřešil tickety a šel spát, a druhý den zase do školy.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        I přes tyto moje problémy se nějak podařilo vydat <span className="text-warning font-weight-bold">legendární Murder Mystery</span>, jedna z posledních legendárních miniher na serveru. A poté BedWars 2.0, které měly inovovat přístup ale pohřbily kompletně BedWars minihru. V létě 2018 se tak stalo, že na minigames serverech nehrál prakticky nikdo, a CraftManii to finančně tížilo, jelikož jsme byli nuceni platit přes 10k měsíčně jenom aby si zahrálo okolo 100 hráčů za den pár miniher. A tak v létě 2018 došlo ke zrušení MiniGames, částečně do toho do dneška lituji.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        V létě 2018 jsme na základě dotazníku se rozhodli udělat krok, který nikdo nečekal. Tzv. <span className="text-primary font-weight-bold">“Přechod na online-mode”</span>. Dle dotazníku, zde totiž hrálo mnoho originálek, i dle statistik připojení to bylo 50:50. Problém, který jsme ale nedopočítali byl ten, který tehdy vytáhl CraftManii, většina hráčů zde hrají s kamarády a né všchni mají originálky. A tak přechod na online-mode naprosto pohřbil server, ne jenom že s přechodem musel být kompletní wipe dat, ale hráči odmítali hrát na serveru. Z 500 hráčů jsme šli na 120, během jednoho měsíce. Poté jsme oznámili, že se CraftMania vrací zpět do warez pole.. bylo už ale pozdě. Damage byl udělen, jak mně tak komunitě, tak všem okolo. Většina lidí tehdy odešla z CM, jelikož jsme jim smazali data a na server kompletně zavrhli.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Nepomohl tomu ani <span className="text-primary font-weight-bold">Prison 2.0</span>, který byl sice revoluční ale nakonec zcela zmatený, to byl ten kde byly doly pojmenovaný po Disney postavách. Nikdo nevěděl kde je, jaký má rank a nakonec ani to nepomohlo.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        CraftMania se topila v problémech, které jsem přenášel z mého realného života do hry blbými rozhodnutími.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        A vše to završil Listopad 2018, kdy jsem mazal staré servery a napsal legendární chybu <code>rm -rf /srv</code> a <span className="text-primary font-weight-bold">smazal tak jedním příkazem celou historii serveru</span>. Celých pět let map, serverů, build map, historie bylo nenávratně pryč. Byla to tak velká chyba, že jsme museli kompletně ten rok zrušil Vánoce, všechny plánované eventy, vše okolo toho. Vyšlo i téma - Omluvný dopis, kde jsem se veřejně omluvil komunitě. Po tomto incidentu jsem si dal na měsíc pauzu od serveru.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Na Vánoce 2018 hrálo na CraftManii okolo 180 hráčů.
                                    </p><br/>
                                    
                                    <h4 className="title mb-4">2019</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Rok 2019 byl pro změnu jiný, změnil jsem i můj přístup. <span className="text-success font-weight-bold">Server se stal víc otevřený</span>, Developeři dostali přístup dělat víc věcí, a celkově se dost věcí na serveru změnilo k lepšímu. Ovšem čekala na nás další tichá bomba, když server nezradí komunita, majitel, kdo by mohl? No přece Mojang s jeho verzí 1.14.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Dne 23.4.2019 vyšla verze Minecraftu 1.14, a jelikož CraftMania kompletně skipla verzi 1.13 jelikož 1.12 servery byly to co drželo CraftManii ještě na živu, tak jsme oznámili update na 1.14. <span className="text-primary font-weight-bold">A tím jsme si vykopali hrob</span>.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Pokud to nevíte, tak ve verzi Minecraftu 1.14 byly přidaný nový Villagerové, který neeetak dobře fungovali. Prakticky vůbec, ve zkratce to dojebalo celý Minecraft. Do dnes je verze 1.14 označována za jednu z nejhorších po 1.9. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Kromě updatů na verzi 1.14 v roce 2019 toho nic novýho nebylo, ano vznikl zde plugin CraftEconomy a desítky malých updatů, avšak oproti tomuto je to nic.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        V listopadu 2019 jsme také uveřejnili <span className="text-primary font-weight-bold">poslední verzi lobby od Zillziho</span>, tedy současné lobby. Tento rok už tu bude pět let, a náhrada asi už nikdy nebude.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Na Vánoce 2019 hráčlo na CraftManii okolo 100 hráčů.
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '450px'}}>
                                        <SRLWrapper>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/lobby-2019-1.png" className="img-fluid" alt="Poslední verze Lobby 2019"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Poslední verze Lobby 2019</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/lobby-2019-2.png" className="img-fluid" alt="Skrytý Haribo Night Club pod spawnem"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Skrytý Haribo Night Club pod spawnem</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/lobby-2019-3.png" className="img-fluid" alt="Ostatky starého lobby schovaný někde v lobby."/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Ostatky starého lobby schovaný někde v lobby.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/halloween-mg-1.png" className="img-fluid" alt="Halloween Minihra: MobArena 2019"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Halloween Minihra: MobArena 2019</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/halloween-mg-2.png" className="img-fluid" alt="Halloween Minihra: MobArena 2019"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Halloween Minihra: MobArena 2019</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </SRLWrapper>
                                    </div>
                                    
                                    <h4 className="title mb-4">2020</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Asi nemusím nikomu připomínat, co se stalo v 2020. Ano je to <span className="text-danger font-weight-bold">COVID</span>. S jeho uzavírkou všech škol, a pobytů doma všechny servery a i služby na internetu explodovali. Do ted si pamatuji že v lednu 100 hráčů, v unoru 320. Tím jak server postupně snižoval parametry, požadavky atd. tak na to nebyl připravený a vzniklo zde tuna problémů. Ovšem dalo by se tomu říct druhý dech.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Všechny servery jsme tehdy vydali ve verzi 1.15, například <span className="text-primary font-weight-bold">Survival: Classic</span> který je tu s námi do dnes na verzi 1.20, vyšel původně na verzi 1.15. Tež vyšel i Skyblock, Creative a jiné. Přidali jsme inovovaný Vanilla Skyblock nazvaný jako SkyCloud, který paraodoval ekonomiku pomocí Waka Banks. Hardcore Vanilla zde také vznikla, kde se automaticky banovalo dle typu smrtí na nějaký čas. A v neposlední řadě na podzim vyšel <span className="text-primary font-weight-bold">Prison 3.0</span>.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Server, který měl v sobě tunu novinek, naprosto nové ranky a změny oproti původním verzím bohužel vydržel pouze do nového roku. Zabila jej chamtivost některých hráčů po multiplierech a moje blbost, tam nedávat limity. Místo toho jsme dali limity na blbé místa a Prison 3.0, tak skončil v 2021 kompletně prázdný.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        I přes to vše, v roce 2020 vyšlo <span className="text-primary font-weight-bold">poslední výroční téma</span>, jelikož od roku 2017 to byl první rok kdy se CraftManii dařilo. A tak si zasloužil souhrn. Ten rok se změnila CraftMania k nepoznání, dohnali jsme vše co zde chybělo, opravili vše co bylo blbě.. ale hráče a vzpomínky to už nikdy nevrátilo.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Na Vánoce 2020 hrálo na CraftManii okolo 150 hráčů.
                                    </p><br/>
                                    <div className="row projects-wrapper" style={{position: "relative", height: '470px'}}>
                                        <SRLWrapper>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/survival-115.png" className="img-fluid" alt="Survival 1.15, dneska známý jako Survival: Classic"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Survival 1.15, dneska známý jako Survival: Classic</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/skycloud.png" className="img-fluid" alt="Inovativní SkyCloud, o který ovšem nebyl nikdy zájem."/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Inovativní SkyCloud, o který ovšem nebyl nikdy zájem.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '0px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/halloween-lobby.png" className="img-fluid" alt="Speciální verze hlavního lobby na oslavu Halloweenu."/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Speciální verze hlavního lobby na oslavu Halloweenu.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '0px', top: '200px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/cm-stats.png" className="img-fluid" alt="Po 5 letech, CraftMania konečně využila statistiky, co se ukládaly od 2015/2018."/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Po 5 letech, CraftMania konečně využila statistiky, co se ukládaly od 2015/2018.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '360px', top: '200px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/dbd-2020.png" className="img-fluid" alt="Dead by Daylight minihra na Halloween"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Dead by Daylight minihra na Halloween.</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mb-4 pb-2 branding" style={{position: "absolute", left: '720px', top: '200px'}}>
                                                    <div className="card border-0 work-container work-grid position-relative d-block overflow-hidden rounded">
                                                        <div className="card-body p-0">
                                                            <a className="mfp-image d-inline-block" href="/" title="">
                                                                <img src="https://static.craftmania.cz/legacy-screens/prison-2020.png" className="img-fluid" alt="Prison 2020"/>
                                                            </a>
                                                            <div className="content bg-white p-3">
                                                                <h6 className="text-muted tag mb-0">Prison 2020</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        </SRLWrapper>
                                    </div>
                                    
                                    <h4 className="title mb-4">2021+</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Po tom všem, po těch letech vedení serveru ve mě začala vznikat <span className="text-primary font-weight-bold">averze vůči Minecraftu</span>, vůči všemu co se ho týkalo. Nedokázal jsem pobrat to, jak to vše dopadlo. Dával jsem si měsíční pauzy, brečel jsem kvuli tromu nespočetněkrát, hledal jsem řešení, řešil to s hráči. CraftManii se nikdy už nepodařilo dostat zpět a jediné co zůstalo byl žal v srdci, vzpomínky na nádherné roky a obrovská sebekritika vůči mým krokům. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        <span className="text-primary font-weight-bold">Nechtěl jsem nikdy aby to dopadlo tak jak to dopadlo</span>, ale někdy to skončit musí, že? Stal jsem se závislý na CraftManii protože jsem nikoho jinýho neměl, staral jsem se o ní každý den, jako o vlastní dítě.. když jsem potřeboval pomohla mi finančně, ted místo toho já finančně podporuji jí s tím, že jí nemohu ukončit protože to nedokážu… 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Probouzím se tu denně s depresemi, uzkostmi… s myšlenkami že to musím ukončit, jinak mě to zníčí… a nikdo mě nikdy nepochopí, ten čas který v tom serveru je, nejde vrátit, nejde získat zpět.. jsou to jenom vzpomínky na hezký roky.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Od roku 2021 se dá říct, že server <span className="text-danger font-weight-bold">vedu už jenom částečně</span>, nejsem již schopen jakkoliv plně figurovat v vedení. Si říkáš, to nejde ale poznat? Proč bych to měl někomu ukazovat, vždyt to musí jít vidět že sám nevím co chci, vyšlo nespočet témat na oživení CM který následně skončily, protože … no protože nevím.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Máte pravdu, nedokážu ukončit CraftManii, nejde to.. z několika důvodů. Když jí ukončím, zabiju sáme sebe, jméno <span className="text-primary font-weight-bold">MrWakeCZ</span>, a to nechci. Server musí běžet dál, dokud na něm nezbyde nikdo jenom já sám, tak jako v realu. Jiná cesta není…
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Na Vánoce 2021 hrálo na CraftManii okolo 50 hráčů, o Vánocích 2022 okolo 10 hráčů.
                                    </p><br/>
                                    
                                    <h4 className="title mb-4">Legacy</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        I přes to vše, jsem rád když vidím, že to někomu pomohlo. I přes to vše, to pomohlo i mně, <span className="text-primary font-weight-bold">psychicky moc ne ale profesně ano</span>. Je zde mnoho lidí, kteří se potkali na serveru a baví se do dnes, nebo se znají IRL a baví se. To je důkaz, že to aspon za něco stálo. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Bylo to <span className="text-primary font-weight-bold">nádherných 10 let</span>, vedení serveru na hru, který z ničeho nic explodoval díky své preciznosti, nadčasovosti a zájmu komunity. To je to, co vždy táhlo CraftManii. I dnes to jde vidět, i přes to, že dva roky na serveru jsou prakticky bez změny, stále je zde komunita hráčů, kteří zde chtějí hrát a věřím, že kdyby byl o Minecraft zajem jako před deseti lety, tak jsme zpět tam kde vždy.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Deset let je ovšem dlouhá doba, když server vznikal měl jsem pár let do maturity, byl ze mě studentík. Dneska jsem už dospělý jedinec, který tu brečí nad minulostí kterou si sám způsobil. Mnoho z vás má už děti, rodinu a jiné věci. Není rok, kdyby mi nepsal někdo z roku 2014, 2015 jak zrovna už má druhé dítě, a že na CraftManii vzpomíná jak na ní hrál, třeba na Skyblocku.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        At chcete nebo ne, myslím si, že CraftMania ve svým prime čase dokázala, co <span className="text-primary font-weight-bold">žádný server nedokázal v česku</span>. Bez větší reklamy překonat ty, které byly potahány youtubery a streamery, dokázala nastolit kvalitu, spravedlivá pravidla, respekt vůči hráčům. Což né na každém serveru funguje, vždy to dopadá tak, že starý AT hejtí server a odejdou jinam. Na CM Discordu prakticky najdete všechny členy AT, co kdyby byli… už to o něčem svědčí.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Server jsem deset let vedl tak jak jsem mohl, vždy stejně a spravedlivě. Vždy byli na prvním místě hráči, před AT, penězy a sebeúctou. A řekl bych, že se to vyplatilo, celkově k dnešnímu dni se na server připojilo přes 1,2 milinou hráčů.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Některé české servery také vychází z CM, hráči si na ní pamatují, starý členové AT si na ní pamatují, a já též.. je to náš společný odkaz - Legacy, a tak se jmenuje i kniha - <span className="text-secondary font-weight-bold">CraftMania: Legacy</span>.
                                    </p><br/>
                                    
                                    <h4 className="title mb-4">Co bude dál?</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Abych pravdu řekl, tak nevím. Kdo by to taky měl vědět? Já asi ano. Jak jsem psal, CraftManii ukončit nedokážu, takže bude pokračovat. V minulých měsících <span className="text-primary font-weight-bold">jsem našel konečně formát a přístup</span>, který vyhovuje i mě, s vedením serveru. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Hráči na serveru hrají, není tedy potřeba ukončovat server.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        CraftMania ovšem už nikdy nebude mít žádné velké změny. Muselo by se sakra něco změnit, abych změnil i já přístup přes to vše. Už roky tvrdím, že jsme se snažili dost, at se snaží jiný. <span className="text-primary font-weight-bold">Server bude i nadále udržovaný</span>, tak aby nebyl zabugovaný. Malé updaty ovšem na serveru budou vycházet i nadále, stejně jako za poslední rok, to je to udržování serveru.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Myslím si, že po tom všem již není potřeba abychom tu tlačili zcela nové servery, s nadčasovými věcmi které stejně už nikdo neocení, koukám na tebe Survival: Lite a Oneblock.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Tento rok chci také vydat knížku, jak jsem jí několikrát zminoval v minulosti i v tomto tématu. <span className="text-warning font-weight-bold">Je to kniha o CraftManii</span>, o mě, o mé historii, o pohledu na vedení na serveru, a co vše okolo toho obnáší. Je to kniha sepsaná jako taková malé detektivní drama a je velmi ale velmi depresivní. Jelikož tam je dopodrobna sepsáno vše, co jsem zmiňoval v tomto tématu. Všechny kauzy, všechny dramata, vše. Jestli si někdy přečtete tuto knihu, až výjde. Tak vám třeba dojdou zpětně některé mé kroky, mé chování, a proč jsem se tak rozhodl.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        V plánech mám také spustit <span className="text-success font-weight-bold">CraftMania: Muzeum</span>, což je server kde budou všechny staré mapy, které se zachovaly po mém incidentu v roce 2018. Je to server, který chci mít spuštěný permanetně aby si, každý kdykoliv mohl vzpomenout, na historii českého Minecraftu, na historii CraftManie a projít si jí pomocí questů a NPC. S experimentováním AI, je možné že to bude plně dabované pomocí AI.
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Doporučuji si ale přečíst na News téma, které dnes nebo zítra výjde s plány na další půl rok, abyste věděli, co očekávat na serveru za menší updaty.
                                    </p><br/>
                                    
                                    <h4 className="title mb-4">Závěr</h4>
                                    <p className="text-muted mx-auto mb-0">
                                        Pokud jsi to dočetl až sem, tak ti chci poděkovat, že jsi projevil zájem toto přečíst. Přečíst si historii jednoho z největších serverů, který si prošel peklem, a nikdy se z něj nedostal. <span className="text-primary font-weight-bold">Možná je čas, jít do toho Endu, že?</span> A ještě něco, stránky CraftMania.cz budou fungovat dokud já budu žít. 
                                    </p><br/>
                                    <p className="text-muted mx-auto mb-0">
                                        Děkuju - <span className="text-primary font-weight-bold">Wake 14.1.2024</span>
                                    </p><br/>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default TenYearsAniversary;
