import React, { Component } from "react";
import PageContainer from "../../components/PageContainer";
import { Link } from "react-router-dom";

class ObchodniPodminky extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundColor: '#2E2958'}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Obchodní podmínky </h4>
									<p className="text-white-50 mt-2">Poslední revize: <span className="text-light">25.5.2024</span></p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item"><Link to="/pravidla">Podmínky používání</Link></li>
												<li className="breadcrumb-item active" aria-current="page">Veřejné obchodní podmínky</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section bg-light">
					<div className="container">
						<div className="row justify-content-center">
							<div className="alert alert-outline-info" role="alert">Dne 25.5.2024 byl oficiálně zrušen Store, a tyto podmínky jsou pouze určeny k archivaci.</div>
							<div className="col-lg-10">
								<div className="card shadow border-0 rounded">
									<div className="card-body">
										<h3 className="card-title">Provozovatel</h3>
										<p>Provozovatelem portálu <strong className="text-primary">CraftMania.cz</strong> je:</p>
										<ul>
											<li>Josef Král, se sídlem: Ztracená 1126/16, Cheb 350 02, Česká republika.</li>
											<li>Identifikační číslo (IČO): <strong className="text-primary">04881907</strong></li>
										</ul>
										<h3 className="card-title">Úvodní ustanovení</h3>
										<ol>
											<li>
												Tyto obchodní podmínky (dále jen "obchodní podmínky“) živnostníka Josef Král, se sídlem
												Ztracená 1126/16, Cheb 35002, zapsaného v živnostenském rejstříku pod IČ <strong>04881907</strong> (dále jen
												"prodávající“ nebo "poskytovatel") upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanský
												zákoník, ve znění pozdějších předpisů (dále jen "občanský zákoník“) vzájemná práva a povinnosti
												smluvních stran vzniklé v souvislosti nebo na základě kupní smlouvy (dále jen „kupní smlouva“)
												uzavírané mezi prodávajícím a jinou fyzickou osobou (dále jen "kupující“) prostřednictvím
												internetového obchodu prodávajícího. Internetový obchod je prodávajícím provozován na webové
												stránce umístěné na internetové adrese <strong>https://store.craftmania.cz/</strong> (dále jen "webová stránka“ nebo "store") a herním serveru.
											</li>
											<li>
												Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Kupní smlouva a obchodní
												podmínky jsou vyhotoveny v českém jazyce. Kupní smlouvu lze uzavřít v českém jazyce.
											</li>
											<li>
												Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou
												dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
											</li>
											<li>
												Pravidla používání služeb sítě CraftMania.cz jsou dostupná k zobrazení na webové stránce <strong>https://craftmania.cz/pravidla</strong> pod názvem Pravidla (dále jen „Pravidla“). Jestliže jsou tyto Obchodní podmínky v rozporu s Pravidly, platí ustanovení uvedené v těchto Obchodních podmínkách.

											</li>
										</ol>
										<h3 className="card-title">Kupní smlouva</h3>
										<ol>
											<li>Webové rozhraní obchodu obsahuje informace o zboží, a to včetně uvedení cen jednotlivého zboží.
												Ceny zboží jsou uvedeny včetně daně z přidané hodnoty (dále jen „DPH“) a všech souvisejících
												poplatků (pokud není uvedeno jinak). Ceny zboží zůstávají v platnosti po dobu, po kterou jsou
												zobrazovány ve webovém rozhraní obchodu nebo herním portálu. Tímto ustanovením není omezena možnost prodávajícího uzavřít kupní smlouvu za individuálně sjednaných podmínek.
											</li>
											<li>
												Pro objednání zboží kupující potvrdí souhlas s obchodními podmínkami a dle vybraného způsobu
												platby platbu dokončí (při zvolení jiné platební metody, než je SMS, je kupující povinen uvést
												správnou e-mailovou adresu na doručení potvrzení o úspěšném dokončení a zaplacení objednávky).
											</li>
											<li>
												Webové rozhraní obchodu obsahuje také informace o případných nákladech spojených s balením a dodáním zboží. Pokud je zboží digitálního charakteru a není prodáváno na fyzickém nosiči, náklady spojení s balením a dodáním zboží jsou 0 Kč a nemusí již být dále uváděny (pokud není uvedeno jinak).
											</li>
											<li>
												Před odesláním platby a dokončením objednávky je kupující povinen si zkontrolovat veškeré zadané údaje platby.
											</li>
											<li>
												Smluvní vztah mezi prodávajícím a kupujícím vzniká úspěšným dokončením objednávky ze strany
												kupujícího (tj. úspěšným dokončením platby – zaplacením objednávky).
											</li>
											<li>
												Prodávající je vždy oprávněn v závislosti na charakteru objednávky (množství zboží, výše kupní ceny, předpokládané náklady na dopravu) požádat kupujícího o dodatečné potvrzení objednávky (například písemně či telefonicky).
											</li>
											<li>
												Kupující souhlasí s použitím komunikačních prostředků na dálku při uzavírání kupní smlouvy.
												Náklady vzniklé kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (především náklady na internetové připojení) si hradí kupující sám.
											</li>
											<li>
												Provozovatel si vyhrazuje právo kdykoliv kupujícímu odebrat výhody v případě porušení Pravidel či
												při případnému uzavření sítě CraftMania.cz.
											</li>
										</ol>
										<h3 className="card-title">Přeprava a dodání zboží</h3>
										<ol>
											<li>
												Při objednání digitálního produktu dodávaného bez fyzického nosiče – přidělení ranku, klíče či kosmetických úprav (dále jen „Výhody“) – dochází k dodání zboží ihned po zaplacení objednávky. V
												případě nedodání zakoupených Výhod do 3 dnů od správného zaplacení objednávky má zákazník
												nárok na podání reklamace objednávky.
											</li>
											<li>
												Kupující je povinen si uchovat potvrzení o platbě pro případnou pozdější reklamaci minimálně jeden rok od vzniku nákupu a platnosti této kupní smlouvy mezi prodávajícím a nakupujícím.
											</li>
											<li>
												V případě, že kupující neuchová potvrzení o platbě a nebude možné ověřit platnost nákupu dodávaného zboží, stává se nákup neplatným.
											</li>
										</ol>
										<h3 className="card-title">Cena zboží a platební podmínky</h3>
										<ol>
											<li>
												Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu způsoby uvedenými na webových stránkách.
											</li>
											<li>
												Prodávající se zavazuje na webových stránkách uvádět vždy aktuální informace o možnostech platby a případných poskytovatelích jednotlivých platebních metod.
											</li>
											<li>
												Provozovatel si vyhrazuje právo kdykoliv změnit cenu prodávaného zboží bez předchozího upozornění.
											</li>
											<li>
												Případné slevy z ceny zboží poskytnuté prodávajícím kupujícímu nelze vzájemně kombinovat (pokud není uvedeno jinak).
											</li>
											<li>
												Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu následujícími způsoby:
												<ul>
													<li>Bezhotovostně prostřednictvím zvoleného platebního systému</li>
													<li>Bezhotovostně pomocí předplacených karet PaySafeCard</li>
												</ul>
											</li>
										</ol>
										<h3 className="card-title">Odstoupení od kupní smlouvy</h3>
										<ol>
											<li>
												Kupující bere na vědomí, že dle ustanovení § 1837 občanského zákoníku nelze odstoupit od kupní
												smlouvy o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu, od kupní
												smlouvy o dodávce zboží, které již bylo aktivováno na herním serveru.
											</li>
											<li>
												Nejedná-li se o případ uvedený v předchozím bodě obchodních podmínek, či o jiný případ, kdy nelze od kupní smlouvy odstoupit,
												má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit,
												a to do čtrnácti (14) dnů od převzetí zboží, přičemž v případě, ve kterém je předmětem kupní smlouvy několik
												druhů zboží nebo dodání několika částí, běží tato lhůta ode dne převzetí poslední dodávky zboží.
												Odstoupení od kupní smlouvy musí být prodávajícímu odesláno ve lhůtě uvedené v předchozí větě.
												Odstoupení od kupní smlouvy může kupující zasílat na adresu sídla společnosti, či na adresu elektronické pošty
												(e-mail) <strong className="text-danger">support@craftmania.cz</strong> nebo na komunikační platformě (dále jen „Discord“) – pomocí Ticket systému.
												Prodávající však doporučuje zasílat odstoupení od kupní smlouvy společně se zbožím, které je předmětem kupní smlouvy.
											</li>
											<li>
												V případě odstoupení od kupní smlouvy dle předchozích bodů obchodních podmínek se kupní smlouva od počátku ruší. Zboží musí být prodávajícímu vráceno do čtrnácti (14) dnů od odstoupení od smlouvy. Odstoupí-li kupující od kupní smlouvy, nese kupující náklady spojené s navrácením zboží prodávajícímu.
											</li>
											<li>
												V případě odstoupení od smlouvy vrátí prodávající peněžní
												prostředky přijaté od kupujícího do čtrnácti (14) dnů od odstoupení od kupní smlouvy kupujícím, a to na bankovní účet kupujícího, který kupující uvede v žádosti o odstoupení od kupní smlouvy.
												Prodávající je taktéž oprávněn vrátit plnění poskytnuté kupujícím již při vrácení zboží kupujícím, či
												jiným způsobem, pokud s tím kupující bude souhlasit a nevzniknou tím kupujícímu další náklady.
												Odstoupí-li kupující od kupní smlouvy, prodávající není povinen vrátit přijaté peněžní prostředky
												kupujícímu dříve, než mu kupující zboží vrátí, nebo prokáže, že zboží prodávajícímu odeslal.
											</li>
											<li>
												Je-li společně se zbožím poskytnut kupujícímu dárek, je darovací smlouva mezi prodávajícím a
												kupujícím uzavřena s rozvazovací podmínkou, že dojde-li k odstoupení od kupní smlouvy kupujícím,
												pozbývá darovací smlouva ohledně takového dárku účinnosti a kupující je povinen spolu se zbožím
												prodávajícímu vrátit i poskytnutý dárek.
											</li>
										</ol>
										<h3 className="card-title">Výhody a balíčky</h3>
										<ol>
											<li>
												Výhody lze zakoupit dle postupu, který je uveden na webových stránkách či síti serverů CraftMania.cz
											</li>
											<li>
												Výhody jsou určeny k zlepšení požitku hraní a odlišení od ostatních účtů bez výhod na síti
												CraftMania.cz.
											</li>
											<li>
												Kupující bere na vědomí, že provozovatel má kdykoliv právo změnit výhody bez povinnosti
												poskytnutí kompenzaci kupujícímu.
											</li>
										</ol>
										<h3 className="card-title">Reklamace</h3>
										<ol>
											<li>Kupující je při podávání reklamace povinen dostatečně doložit, že řádně zaplatil objednanou službu (V případě platby pomocí SMS screenshotem, případně dostatečně čitelnou fotkou, na které bude vidět datum a čas odeslání SMS, její tvar, číslo, na které byla SMS odeslána, a případně zpětná SMS, kterou poskytovatel plateb zaslal zpět. V případě jiné platby poté získaným potvrzením o zaplacení služby.)</li>
											<li>Kupující je oprávněn podat reklamaci prodávajícímu v případě, že:</li>
											<ul>
												<li>Nebylo doručeno zakoupené zboží.</li>
												<li>Částka uvedená na webových stránkách se neshoduje s částkou, kterou kupující zaplatil.</li>
												<li>Kupující odeslal platbu ve špatném formátu (v případě SMS). Tyto platby jsou poté řešeny s poskytovatelem platební brány.</li>
												<li>Kupující uvedl chybné údaje.</li>
											</ul>
											<li>Prodávající je oprávněn odmítnout reklamaci v případě, že:</li>
											<ul>
												<li>Kupující odeslal platbu ve špatném formátu (nejčastěji uvedené špatné uživatelské jméno) a kupující není schopen dodat dostatečné potvrzení o zaplacení.</li>
												<li>Kupující odeslal platbu ve špatném tvaru a platbu nebylo možné dohledat poskytovatelem plateb.</li>
												<li>Kupujícímu skončil časový nárok na zakoupené výhody.</li>
											</ul>
											<li>Kupující může podat reklamaci pomocí Ticket systém na Discord serveru <a href="https://discord.gg/craftmania">https://discord.gg/craftmania</a>, popřípadě odeslat email s reklamací na <strong className="text-danger">support@craftmania.cz</strong>. V případě, že kupující použije jinou metodu nemusí být reklamace vyřízena či naní být odpovězeno.</li>
											<li>Prodávající se zavazuje začít řešit reklamaci nejdéle do 7 dní od podání reklamace kupujícím.</li>
											<li>Reklamace včetně odstranění vady musí být vyřízena a kupující o tom musí být informován nejpozději do třiceti (30) dnů ode dne uplatnění reklamace, pokud se prodávající s kupujícím nedohodne na delší lhůtě. Je-li předmětem závazku poskytnutí digitálního obsahu, včetně digitálního obsahu dodaného na hmotném nosiči, nebo služby digitálního obsahu, musí být reklamace vyřízena v přiměřené době s přihlédnutím k povaze digitálního obsahu nebo služby digitálního obsahu a k účelu, pro nějž je kupující požadoval.</li>
										</ol>
										<h3 className="card-title">Doručování</h3>
										<ol>
											<li>Kupujícímu může být doručováno na elektronickou adresu kupujícího.</li>
											<li>Kupujícímu může být doručována SMS související se zbožím nebo službami.</li>
										</ol>
										<h3 className="card-title">Zákaznická podpora</h3>
										<ol>
											<li>
												V případě problémů s využíváním služeb má kupující možnost kontaktovat zákaznickou podporu dle informací 
												uvedených na webové stránce https://craftmania.cz/kontakt
											</li>
											<li>Preferovaný systém komunikace s kupujícím je pomocí Ticket systému, který je dostupný na Discord serveru - <a href="https://discord.gg/craftmania">https://discord.gg/craftmania</a>. Pokud zákazník nepoužívá Discord, může použít náš email <strong className="text-danger">support@craftmania.cz</strong>.</li>
											<li>Kupujícímu nenáleží žádná forma kompenzace v případě pozdní odpovědi ze strany poskytovatele.</li>
											<li>
												V případě nejasného specifikování dotazu či zvolení nesprávné formy komunikace není 
												poskytovatel povinen poskytnout odpověď. Poskytovatel nezaručuje dostupnost telefonického kontaktu, 
												doporučujeme využít alternativní metody kontaktování společnosti.
											</li>
											<li>Doba odpovědi na zprávu od zákazníka může trvat až 5 dní dle zvoleného typu kontaktování naší zákaznické podpory.</li>
										</ol>
										<h3 className="card-title">Další práva a povinnosti smluvních stran</h3>
										<ol>
											<li>Zákazník nemá nárok na přeprodávání zboží dalším osobám.</li>
											<li>Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení §1826 odst. 1 písm. e) občanského zákoníku.</li>
											<li>K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce, se sídlem Gorazdova 1969/24, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: https://adr.coi.cz/cs. Platformu pro řešení sporů on-line nacházející se na internetové adrese http://ec.europa.eu/consumers/odr je možné využít při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.</li>
										</ol>
										<h3 className="card-title">Ochrana osobních údajů</h3>
										<ol>
											<li>Veškeré informace o ochraně osobních údajů a s jejch nakládáním jsou vždy uvedeny v <a href="https://craftmania.cz/pravidla/zasady-zpracovani-osobnich-udaju/">Zásady zpracování osobních údajů</a></li>
										</ol>
										<h3 className="card-title">Ukončení provozu herního serveru</h3>
										<ol>
											<li>V případě ukončení provozu herního serveru zanikají veškeré uživatelské herní účty (Iron, Gold, Diamond, Emerald, Obsidian a Amethyst - VIP) bez nároku na finanční či jinou kompenzaci.</li>
											<li>V případě ukončení provozu herního serveru dochází k okamžité expiraci veškerých neaktivovaných kódů na slevy nebo aktivaci VIP výhod.</li>
										</ol>
										<h3 className="card-title">Závěrečné podmínky</h3>
										<ol>
											<li>
												Kupující je povinen vždy uvádět pouze přesné, úplné a pravdivé informace a ve svých
												zprávách se vyjadřovat slušně. V opačném případě nemusí být kupujícímu podpora poskytnuta.
											</li>
											<li>Prodávající není povinen odpovídat na dotazy netýkající se poskytovaného zboží či služeb.</li>
											<li>Kupující nemá nárok na přeprodávání zboží dalším osobám.</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default ObchodniPodminky;
