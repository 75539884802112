import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component<any, any> {

	public render() {
		return (
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
							<h4 className="text-light footer-head">O CraftManii</h4>
							<p className="mt-4">CraftMania.cz byla založena dne 21.12.2013. Jednalo se o malý pěti slotový minecraft server pro kamarády. Za méně jak 10 měsíců se stal server 3. největším serverem v CZ/SK a zároveň v TOP 10 serverů na CC.</p>
						</div>

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<h4 className="text-light footer-head">Užitečné odkazy</h4>
							<ul className="list-unstyled footer-list mt-4">
								<li><a href="https://news.craftmania.cz/" className="text-foot" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-chevron-right mr-1"/> Novinky</a></li>
								<li><a href="https://wiki.craftmania.cz/" className="text-foot" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-chevron-right mr-1"/> Wiki</a></li>
								<li><a href="https://stats.craftmania.cz/" className="text-foot" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-chevron-right mr-1"/> Statistiky</a></li>
								<li><a href="https://banlist.craftmania.cz/" className="text-foot" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-chevron-right mr-1"/> Banlist</a></li>
								<li><a href="https://status.craftmania.cz/" className="text-foot" target="_blank" rel="noopener noreferrer"><i className="mdi mdi-chevron-right mr-1"/> Status page</a></li>
								<li><Link to="/o-serveru" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> O CraftManii</Link></li>
							</ul>
						</div>

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							<h4 className="text-light footer-head">Podmínky a kontakt</h4>
							<ul className="list-unstyled footer-list mt-4">
								<li><Link to="/pravidla/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Pravidla serveru</Link></li>
								<li><Link to="/pravidla/obchodni-podminky/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Obchodní podmínky</Link></li>
								<li><Link to="/pravidla/povolene-mody/" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Povolené a zakázané módy</Link></li>
								<li><Link to="/pravidla/zasady-zpracovani-osobnich-udaju" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Zásady zpracování osobních údajů</Link></li>
								<li><Link to="/kontakt" className="text-foot"><i className="mdi mdi-chevron-right mr-1"/> Kontakt</Link></li>
							</ul>
						</div>

						<div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
							{ /* JEBAIT */}
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
