import { GhostAPIPost } from "../interfaces/GhostAPI";
import { IServerStatistics, IServerStatus, ATSMember } from "../interfaces/IServerStatistics";

export const fetchNewsPost = async (): Promise<GhostAPIPost[]> => {
	return await fetch('https://news.craftmania.cz/ghost/api/v3/content/posts/?key=119f8981805124c800084606f9&include=tags,authors')
		.then((res: Response) => res.json())
		.then((result: any) => {
			return result.posts;
		});
};

export const fetchServerStats = async (): Promise<IServerStatistics> => {
	return await fetch('https://api.craftmania.cz/server/statistics')
		.then((res: Response) => res.json())
		.then((result: any) => {
			return result.data;
		});
};

export const fetchServerPlayers = async (): Promise<IServerStatus> => {
	return await fetch('https://api.craftmania.cz/server/playercount')
		.then((res: Response) => res.json())
		.then((result: any) => {
			return result.data;
		});
};

export const fetchAdminTeamList = async (): Promise<ATSMember[]> => {
	return await fetch('https://api.craftmania.cz/admin/ats')
		.then((res: Response) => res.json())
		.then((result: any) => {
			return result.data;
		});
};
