import React from 'react';
import { Component } from "react";
import HeroTitle from "./HeroTitle";
import MainInfoBoxes from './TopProperties';
import ServerStats from "./ServerStats";
import PageContainer from "../../components/PageContainer";
import LatestNews from './LatestNews';

class HomePage extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				{/* Hlavní hero title */}
				<HeroTitle/>

				{/* Server statistiky */}
				<ServerStats/>

				{/* Informace o serveru */}
				<MainInfoBoxes/>

				{/* Poslední novinky z news.craftmania.cz */}
				<LatestNews/>

			</PageContainer>
		);
	}
}

export default HomePage;
