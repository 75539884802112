import React from "react";
import { Component } from "react";
import { ATSMember } from "../interfaces/IServerStatistics";
import { fetchAdminTeamList } from "../utils/FetchUtils";
import { Link } from "react-router-dom";
import PageContainer from "../components/PageContainer";

interface AdminTeamState {
	adminTeamList: ATSMember[] | null;
}

class AdminTeam extends Component<any, AdminTeamState> {

	constructor(props: any) {
		super(props);
		this.state = {
			adminTeamList: null
		};
	}

	public async componentDidMount() {
		let apiATSMembers = await fetchAdminTeamList();
		apiATSMembers.sort(((a: ATSMember, b: ATSMember) => b.rank - a.rank));
		this.setState({
			adminTeamList: apiATSMembers
		});
	}

	public render() {
		if (this.state.adminTeamList === null) {
			return null;
		}
		return (
			<PageContainer>
				<section className="bg-half admin-team-gradient d-table w-100">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Admin Team </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Na této stránce najdeš aktuální seznam všech členů AT.</p>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item">Ostatní</li>
												<li className="breadcrumb-item active" aria-current="page">Admin Team</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section">
					<div className="container">

						<div className="row">
							{buildAdminList(this.state.adminTeamList)}
						</div>
					</div>
				</section>
			</PageContainer>

		);
	}

}

const buildAdminList = (members: ATSMember[]) => {
	let finalArray: JSX.Element[] = [];
	for (let item of members) {
		const member = item as ATSMember;
		finalArray.push(
			<div className="col-lg-3 col-md-6 mt-4 pt-2">
				<div className="card text-center border-0">
					<div className="position-relative">
						<a href={"https://stats.craftmania.cz/player/" + member.nick} target={"_blank"} rel={"noopener noreferrer"}>
							<img src={"https://visage.surgeplay.com/bust/" + member.uuid} className="img-fluid avatar avatar-ex-large rounded" alt=""/>
						</a>
					</div>
					<div className="card-body py-3 px-0 content">
						<a href={"https://stats.craftmania.cz/player/" + member.nick} target={"_blank"} rel={"noopener noreferrer"}>
							<h5 className="mb-0"><div className="name text-dark">{member.nick}</div></h5>
						</a>
						<small className="designation text-muted">{resolveRoleBadge(member.rank)}</small>
					</div>
				</div>
			</div>
		);
	}
	return finalArray;
};

const resolveRoleBadge = (id: number) => {
	if (id === 1) {
		return <span className="badge badge-discord-mod"> Discord Moderátor </span>;
	}
	if (id === 2) {
		return <span className="badge badge-helper"> Helper </span>;
	}
	if (id === 3) {
		return <span className="badge badge-helper"> Helperka </span>;
	}
	if (id === 4) {
		return <span className="badge badge-admin"> Admin </span>;
	}
	if (id === 5) {
		return <span className="badge badge-admin"> Adminka </span>;
	}
	if (id === 6) {
		return <span className="badge badge-builder"> Builder </span>;
	}
	if (id === 7) {
		return <span className="badge badge-eventer"> Eventer </span>;
	}
	if (id === 8) {
		return <span className="badge badge-artist"> Artist </span>;
	}
	if (id === 9) {
		return <span className="badge badge-developer"> Developer </span>;
	}
	if (id === 11) {
		return <span className="badge badge-manager"> Manager </span>;
	}
	if (id === 12) {
		return <span className="badge badge-owner"> Owner </span>;
	}
	return <span className="badge badge-primary"> Neznámá pozice </span>;
};

export default AdminTeam;
