import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { inject } from '@vercel/analytics';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { BrowserRouter } from 'react-router-dom';

inject();

ReactDOM.render(
		<BrowserRouter>
			<App />
		</BrowserRouter>,
	document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
