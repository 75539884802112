import React from "react";
import { Component } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageContainer from "../../components/PageContainer";

class Pravidla extends Component<any, any> {

	public render() {
		return (
			<PageContainer>
				<section className="bg-half d-table w-100" style={{backgroundColor: '#784458'}}>
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-12 text-center">
								<div className="page-next-level">
									<h4 className="title text-white"> Podmínky používání </h4>
									<p className="text-white-50 para-desc mb-0 mx-auto">Zde najdeš základní pravidla serveru, a také naše další podmínky a informace.</p>
									<p className="text-white-50 mt-2">Poslední revize: <span className="text-light">18.1.2024</span></p>
									<Link to="/pravidla/obchodni-podminky">
										<Button className="btn btn-dark mt-4 mr-2 shadow-none mt-3">
											Obchodní podmínky
										</Button>
									</Link>
									<Link to="/pravidla/zasady-zpracovani-osobnich-udaju/">
										<Button className="btn btn-dark mt-4 mr-2 shadow-none mt-3">
											Zásady zpracování osobních údajů
										</Button>
									</Link>
									<div className="page-next">
										<nav aria-label="breadcrumb" className="d-inline-block">
											<ul className="breadcrumb bg-white rounded shadow mb-0">
												<li className="breadcrumb-item"><Link to="/">Domů</Link></li>
												<li className="breadcrumb-item active" aria-current="page">Podmínky používání</li>
											</ul>
										</nav>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="section bg-light">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-10">
								<div className="card shadow border-0 rounded">
									<div className="card-body">
										<h3 className="card-title">Práva a povinnosti</h3>
										<ol className="">
											<li>Hráč registrovaný na serverech CraftMania.cz souhlasí s tím, že vše, co poskytne, nahraje, napíše nebo daruje, není nijak právně vymahatelné.</li>
											<li>Hráč <strong className="text-danger">musí</strong> dodržovat platné zákony České a Slovenské republiky.</li>
											<li>Neznalost pravidel nijak neomlouvá!</li>
											<li>Hráč je povinen respektovat vedení serveru.</li>
											<li>Pokud hráč podává stížnost na člena vedení serveru nebo na hráče, který je v rozporu s pravidly, je povinen předložit přesné důkazy bez úpravy - fotografie, data, výpisy.</li>
											<li>Hráč má právo hrát zdarma, pokud neporušuje pravidla serveru nebo zákony ČR, popřípadě Ústavu ČR či SK. V případě porušení zákonů ČR či SK, nebo ústavy ČR či SK bude vykázán ze serveru.</li>
											<li>Hráč má právo na svobodu a vlastní názor, pokud jimi však neporušuje pravidla či nenarušuje svobodu nebo vlastní názor někoho jiného.</li>
											<li>Není tolerován rasismus, urážky, pomluvy, nacizmus a jiná hnutí.</li>
											<li>Každý hráč má právo na ochranu své IP adresy a osobních údajů. V případě, že hráč bude sdílet cízí údaje (data, fotografie, reálné jména, adresu) bez povolení dané osoby, bude neprodleně vykázán ze serveru.</li>
											<li>Pokud si hráč nepřeje, aby byly zveřejněny jeho osobní údaje, telefonní čísla, apod., ostatní hráči to musí respektovat a nezneužívat.</li>
											<li>Je přísně zakázáno používat jakékoliv prostředky, které skrývají nebo mění reálné informace o připojovaném hráči a připojování se prostřednictvím těchto prostředků na naše servery. Zneužívání dynamické (proměnlivé) IP adresy za účelem porušování zde uváděných pravidel, bude mít za následek odeslání logů (záznamů) poskytovateli internetového připojení, kde je IP adresa registrována a bude požadováno okamžité ukončení této činnosti.</li>
											<li>Hráč není povinen nahlásit konání jiného hráče, pokud je činnost v rozporu se zde uvedenými pravidly.</li>
											<li>Majitel serveru jakožto vlastník si nárokuje právo smazat kohokoliv ze serveru bez udání důvodu v případě, že daný hráč aktivně porušuje pravidla, chová se nevhodně a nebo provádí jiné metody v rámci urážení/seskupování. V případě, že k tomuto kroku dojde, majitel serveru není nijak povinen vykazovat informace a nelze se nijak odvolat, jelikož se jedná o jeho server.</li>
											<li>Všechny postavy uložené na serverech jsou majetkem CraftMania.cz a jejich existence, zachování při porušení pravidel ani jiné nakládání s nimi není právně vymahatelné.</li>
											<li>Jestliže hráč dostane oprávněný dočasný ban, tak nemá právo si zažádat o unban.</li>
											<li>Používání Auto clickeru, double-clicků a jiných klikacích metod (kromě Jitteru, Butterfly), je zakázáno. Pro prověření této situace se bude člen AT dotazovat hráče, zda nepoužívá nějakou ze zakázaných klikacích metod a případně mu bude oznámeno, ať jej používat přestane, jelikož mu může být udělen trest. Za předpokladu, že hráč nebude reagovat, bude hráč teleportován na spawn a pokud bude hráč nadále klikat, tak mu bude udělen trest za využívání zakázaných klikacích metod.</li>
											<li>AFK farmy jsou povolené avšak farma nesmí nijak porušovat pravidla týkající se staveb a velikostí (gigantické rozměry). Platí také to, že na IP může být AFKující hráč pouze jeden bez použití více účtů nebo sourozenců. Tedy: 1 hráč u jedné farmy může afkovat do doby, než jej portne detekce AFK na spawn.</li>
											<li>Obcházení AFK detekce na serveru je zakázáno. V případě, že hráč bude jakkoliv obcházet detekci AFK, aby jej neteleportovala na spawn bude udělen permanení ban.</li>
											<li>Za důkaz je považován screen nebo video obsahující porušení pravidel hráče. Důkaz nesmí být nijak upravený - ustřižený. <strong className="text-danger">Důkaz má maximální platnost 14 dní.</strong> Za důkaz není brán screen CoreProtectu starší aktivity víc jak 30 dní.</li>
										</ol>
										<h3 className="card-title">Všeobecné zákazy</h3>
										<ol>
											<li>Je zakázáno lhát adminům či jiným osobám pověřeným vedením serveru. Za lhaní se bere také šíření neplatných nebo lživých informací o serveru nebo členech admin teamu.</li>
											<li>Je přísně zakázáno využívat jakýchkoliv bugů na serveru. Pokud se vyskytne jakýkoliv glitch k získání peněz, op itemů, hlav, klíčů apod, hráči jsou povinni okamžitě nahlásit daný problém. V případě, že i nadále bude hráč tohoto bugu zneužívat server mu může kdykoliv bez udání důvodu smazat jakékoliv data z serveru.</li>
											<li>Je zakázáno hledat chyby (mezery) v pravidlech. Využívání těchto chyb (mezer) je přísně zakázáno a trestá se banem. Co není zmíněno zde v pravidlech, neznamená, že je povoleno.</li>
											<li>Je přísně zakázáno jakékoliv používání hacků, cheatů a zakázaných módů. Pokud se hráč připojí s jakýmkoliv cheatlauncherem, př. Huzuni, Metro, Wurst, Wolfram automaticky souhlasí s tím, že dostane neprodleně po zjištění ban. Daný ban má pak stejnou váhu, jako kdyby byl hráč chycený při používání cheatů.</li>
											<li>Je přísně zakázáno se vydávat za člena AT. Toto pravidlo platí i na vlastní skin.</li>
											<li>PvP je povoleno na všech serverech. Tzn. že hráč odpovídá za svoje věci v inventáři, které ztratí uvnitř PvP oblastí. Server nemá v povinnosti tyto věci nahrazovat, nejedná-li se o chybu.</li>
											<li>Je zakázáno vytvářet seznamky, stavby s sexuální tématikou. Toto pravidlo také platí na názvy warpů, regionů nebo entity. V případě porušení dojde k udělení banu dle trestů a smazání stavby bez náhrady.</li>
											<li>Hráč nesmí po Adminech požadovat levely, itemy, coiny, skilly apod. Pozn. Vyjímka případů opravy postav, které mají zřejmý bug, který byl předem prokázán majitelem.</li>
											<li>Je zakázáno navádění hráčů k porušování pravidel.</li>
											<li>Server neručí za ztracené věci umístěné v Item Framu (rámeček), to stejné platí i pro ArmorStand. Důvodem je, že se tyto věci se chovají jako Entity nebo-li mobové.</li>
											<li>Spawnkill a Tpakill je přísně zakázaný (vyjímky Vanilla: Anarchy). Spawnkill je okamžité zabíjení hráče po respawnu - hráč nemůže utéct. Za spawnkill je také bráno obsazení území cizího teamu a následné bránění v respawnu. Tpakill je okamžité zabíjení hráče po teleportu na jeho polohu.</li>
											<li>Nick hráče nesmí obsahovat jakékoliv sprosté slovo.</li>
											<li>Hráč nesmí vlastnit žádný OP item - tedy blok/item, který má nepovolené (všechny) enchanty, nebo potiony k trollingu a jiné věci, které nejsou normální. V případě vlastnění dostane hráč trest podle toho kolik vlastní dané věci. Může se také stát, že pozemek/ostrov/residenci vedení serveru smaže, bez udání důvodu.</li>
											<li>V případě prokázání Multi-Account tj. Multi-účet bude hráči udělen IP ban. Za multi-učet je bráno hraní na dvou postavách - př. dva účty hrající jednu hru na MiniGames (pokud jeden stojí a druhý hraje, pokud oba hrají nejedná se o porušení!). Za multi-učet není bráno dočasné připojení osoby v rámci výměny itemů/peněz na jinou postavu. Za multi-účet se považuje také využívání dalších účtu k rozšíření limitu vlastnících residencí/spawnerů. Za Multi-účet je také bráno připojení více účtů najednou za účelem obohacení se (VoteParty, možnost vícekrát hlasovat,...)</li>
											<li>Je přísně zakázáno nadávat, používat vulgární výrazy, urážet či provokovat ostatní a spamovat.</li>
											<li>Je zakázáno psát reklamu, za reklamu jsou brány všechny konkurenční servery (i zahraniční). <strong className="text-danger">Název serveru není reklama</strong>.</li>
											<li>V případě, že hráč staví sprosté stavby kdekoliv na serveru (zahrnuje i minihry), mu bude udělen ban.</li>
											<li>Teamování + crossteamování v minihrách je zakázano.</li>
											<li>Je zakázán jakýkoliv druh napovídání ve hře ostatním teamům.</li>
											<li>Je přísně zakázáno podkopávat nebo jinak kazit hru vlastnímu teamu.</li>
											<li>V případě, že se minihra uchyluje ke konci (poslední dva týmy jsou naživu), je zakázano jakýkoliv druh zdržování hry. Jako zdržování hry se dá usvědčit camping pod ostrovem/stavění se do nadměrných výšek a následný camping. Neplatí ale pokud se dané teamy domluví.</li>
											<li>V případě, že hráč pořádá event pro hráče, nikdo na tomto eventu nesmí zemřít, v případě porušení bude udělen ban podle pravidel. Tzn., že hráč nesmí skákat do lávy, voidu, být utlačován do zdi atd.</li>
											<li>Je zakázáno vést různé DDoS/DoS/bot útoky proti serveru.</li>
											<li>Hráč vlastnící právo na pokládání Stickerů nesmí jakkoliv porušovat danou činností jakkoliv pravidla. Př. umisťování hakových křížů, znaky rasismu, terorismu atd.</li>
											<li>Teamovani hráčů na <strong className="text-danger">warp pvp</strong> je přísně zakázán a může se trestat banem.</li>
											<li>Jestliže hráč bude neustále otravovat člena admin týmu s žádostí o udělení trestu, může mu být udělen permanentní ban za pokus o trolling a již nikdy nemusí být udělen unban.</li>
											<li>Je zakázáno hrát s jakýmkoliv zakázaných módů.</li>
											<li>Je zakázáno sdílet jakékoliv osobní informace o hráčích, zejména IP, jméno a příjmení, bydliště, datum narození.</li>
											<li>Je zakázáno si dávat nick, který nějak uráží nebo poukazuje na jiného hráče.</li>
											<li>Do CMD spamu, tedy spamu za pomocí nějakého příkazu jsou zahrnuty také příkazy jako <code>/pay</code>, <code>/msg</code>, <code>/friends msg</code> a jakékoliv jiné příkazy, které zasílají výslednou zprávu jinému hráči.</li>
											<li>Je zakázáno dělat fake eventy, tedy eventy které se reálně nekonají nebo jsou tvořeny za účelem okrádat/zabíjet hráče.</li>
											<li>Je zakázáno vytvářet fake itemy - přejmenování server itemů. Například. EpicCrate na VoteCrate a naopak.</li>
											<li>Je zakázáno jakkoliv obchodovat s itemy, účty a jinými věcmi na serveru za realné peníze. Pokud se i tak daný obchod provede, server nijak dané věci nebude nahrazovat a ani peněžní hodnotu vloženou do obchodu.</li>
											<li>Je zakázáno jakkoliv trollovat/otravovat záměrně ostatní hráče pomocí itemů, příkazů a jiných možností, které hráč může provádět.</li>
											<li>Je zakázáno vyhrožovat hráčům za účelem udělení (nepravděpodobného) trestu. Př. vyhrožování kickem ze hry, mutem a banem.</li>
											<li>Je zakázáno jakkoliv obcházet udělené tresty, platí pro mute a ban. V případě porušení a následném zjištění bude hráči udělen okamžitě vyšší trest bez možnosti zrušení.</li>
											<li>Tresty se počítají napříč všemi vlastněnými účty hráče dle IP. Pokud má hráč dynamickou IP, je tato podmínka posuzována individuálně aby nedošlo k nedorozumění.</li>
											<li>Je zakázáno vytvářet skupiny/teamspeak servery/discord servery s názvem Craftmania.cz a propagovat jej na serveru. Oficiální servery/skupiny jsou vždy odkazovány na Wiki/Webu Craftmanie. Toto pravidlo neplatí v případě, že je jasně na dané platformě zdůrazněno, že se nejedná o oficiální učet s podporou vedení serveru.</li>
											<li>Je zakázáno připojit se na server se skinem, který je urážlivý, sexuální, nacistický či jakkoliv jinak nevhodný. Př. Hitler, hákové kříže, nahá žena, Stalin, srp.</li>
											<li>Vyhledávání diamantů (Diamond Ore) skrz metodu výpočtu bloků v bažinách (Swamp), řekách a po celém světě, je bráno jako porušení pravidel o x-rayi. Tato metoda těžení je zakázána z důvodu znehodnocování hodnoty diamantů.</li>
											<li>Je zakázáno psát do veřejného chatu jinými jazyky než jsou - Čeština, Slovenština a Angličtina.</li>
										</ol>
										<h3 className="card-title">Vedení serveru</h3>
										<ol>
											<li>Člen Admin Teamu (AT) dále jen "člen vedení serveru" je hráč, který má rank Helper a vyšší.</li>
											<li>Člen vedení serveru se nesmí za žádných okolnostností povyšovat nebo porušovat zde uvedená pravidla.</li>
											<li>Členi vedení serveru nemají v povinnosti předkládat důkazy či jiné materiály (bany, kicky, jaily) hráčům. Tato povinnost platí pouze při požádání majitelem serveru.</li>
											<li>Člen vedení serveru se za žádných okolností nesmí zúčastnit PVP bojů na <code>/warp pvp</code>!</li>
											<li>Člen vedení serveru (pokud má Gamemode) nesmí vlastnit vlastní obchod s itemy, a narušovat tak ekonomiku serveru.</li>
											<li>Člen vedení serveru nesmí zneužívat svých pravomocí na serveru a měnit tak ekonomiku serveru. Př. kopírování farem, kopírování obchodů atd.</li>
											<li>Člen vedení serveru <strong className="text-danger">musí</strong> vlastnit originální Minecraft účet.</li>
											<li>Člen vedení serveru nesmí vlastnit žádný větší server nad 5 slotů nebo být v AT na jiném serveru. V případě porušení je daný člen neprodleně vyhozen z AT. Za určitých podmínek lze udělit vyjímku.</li>
											<li>Člen vedení serveru nesmí rozdávat itemy z creativu a ani je nijak uchovávat jako itemy získané hraním.</li>
											<li>Člen vedení serveru nesmí provádět jakýkoliv typ trollingu na hráče, jak na serverech CM, tak na jiných CZ/SK serverech.</li>
											<li>Člen vedení serveru nesmí zabanovat hráče na základě kicku Helpera (př. cheaty), pokud není kick podložený důkazy o daném problému.</li>
											<li>Pokud se člen vedení serveru začne hádat na serveru, bude náležitě potrestán. Pokud založí hádku, bude neprodleně vyhozen, pokud vstoupí do hádky, budou mu odebrány práva na 14 dní.</li>
											<li>Majitel nebo hlavní admin si může od člena vyžádat kdykoliv důkazy o banu/mute/kicku, který byl udělen v posledních 30 dnech. V případě nedoložení není možné trest prokázat, tedy lze ho brát jako neplatný, na základě čehož se uděluje příslušný trest dle bodového systému.</li>
											<li>V případě, že vedení serveru bude konat jakýkoliv Event pro celý server (BedWars turnaj, Annihilation turnaj, SkyWars turnaj), na event musí dohlížet nejméně 3 osoby z vedení serveru.
												Vedení serveru má zakázáno pomáhat hráčům s WorldEditem (neplatí pro Creative), rozdávat hlavy své i jiných členů AT apod. věci, které zvýhodňují hráče.</li>
											<li>Vedení serveru je povinné správně napsat důvod trestu. Tzn. řádně ho rozepsat, tak aby tomu rozuměl každý. V případě, že se tak nestane, je trest neplatný, jelikož není možné prokázat, za co byl trest udělen (například zkráceniny atd.).</li>
											<li>Vedení serveru musí dodržovat stejný nebo podobný nick na webu, Discordu, Minecraftu a na všem, co se týká CraftManie.</li>
										</ol>
										<h3 className="card-title">Herní účet a účty</h3>
										<ol>
											<li>Obchodování s Minecraft/Web/Discord účtem na CraftMania.cz je přísně zakázáno. V případě zjištění, může být daný učet kompletně smazán ze serveru bez jakékoliv náhrady.</li>
											<li>Nelze měnit stav účtu, v případě že je na něm aktivní ban. Tzn. nelze si požádat o manuální převod VIP, peněz, statistik atd.</li>
											<li>V případě, že hráč žádá zrušení ochrany na pozemku/ostrovu/residenci nebo jiné ochraně musí hráč, vlastnící daný region být offline nejméně 6 měsíců, aby hráč, který žádá zrušení dostal přístup. V opačném případě musí počkat nebo požádat o povolení u daného hráče, kterému region patří.</li>
											<li>Obchodování nebo-li prodej (i nákup) jakýchkoliv účtů na serveru, výhody, her a jiných předmětů za <strong className="text-danger">reálné peníze</strong> je přísně zakázáno. V případě zjištění je prodejci udělen ban a jeho účet (a pokud se to týká i jiných účtů) může být smazán bez jakékoliv náhrady. <i>Toto pravidlo neplatí na obchody za herní měnu - př. hráč dá určitou částku CC/itemů jinému hráči a ten mu koupí VIP.</i></li>
										</ol>
										<h3 className="card-title">VIP & Store</h3>
										<ol>
											<li>Nákupem na Storu hráč automaticky souhlasí s Veřejnými obchodními podmínkami serveru CraftMania.cz</li>
											<li>Hráč vlastnící VIP nemá žádnou vyjímku z pravidel serveru.</li>
											<li>Vyhrazujeme si právo na jakoukoliv okamžitou změnu výhod VIP.</li>
											<li>Vyhrazujeme si právo na odebrání VIP bez udání důvodu.</li>
											<li>Jakékoliv VIP je nevratné, tzn. nelze vrátit peníze!</li>
											<li>V případě, že hráč požádá o chargeback/refund bude mu zamítnut. Toto pravidlo lze domluvit po důkladném ppřezkoumání a dohodě s majitelem serveru.</li>
											<li>V případě nákupu přes Store serveru, hráč automaticky souhlasí s uvedením osobních údajů společnostem Stripe a PayPal.</li>
											<li>V případě, že bude na serveru wipe, tzv. restart serveru (vše se smaže), tak jednorázové itemy, které získá hráč při zakoupení VIP, nejsou nijak vymahatelné.</li>
										</ol>
										<h3 className="card-title">Stavby</h3>
										<ol>
											<li>Za griefing na serveru je považováno toto:
												<ul>
													<li>Jakýkoliv druh níčení stavby, který danému hráči nepatří (nemá tedy na něm povolení stavět).</li>
													<li>Jakékoliv <strong className="text-danger">úmyslné</strong> ničení stavby a jejího okolí patřící jinému hráči. Příklady: LavaCast, odstřelování s firebally nebo end krystaly, tnt destrukce, redstone obvody a jiné.</li>
													<li>Zníčení stavby pokud hráč byl na něj přidán a následně ho zníčil.</li>
													<li>Jakékoliv vykradení regionu po přidání práva stavět př. shulkerboxy, truhly, shopy.</li>
													<li>Stavění Lava Castu za účelem griefovat, jakkoliv poškodit bližší stavbu nebo krajinu.</li>
													<li>Zapalování krajiny, která není chráněná ochranou (Residence, Lands atd.)</li>
													<li>Toto pravidlo neplatí na Vanilla: Anarchy</li>
												</ul>
											</li>
											<li>Usmrcení hráče na PVP oblasti je beztrestné.</li>
											<li>Za usmrcení hráče v Residenci/Lands s povoleným PVP server neodpovídá.</li>
											<li>Za usmrcení hráče v NO-PVP (cheaty/bug) bude přidělen trest IP ban.</li>
											<li>Počet spawnerů 25 je limitován na konkrétní region, který vlastní hráč. Tzn. že jeden hráč smí mít libovolně na svém ostrově jakkoliv rozmístěných 25 spawnerů. Pro servery s Residencí je počet spawnerů počítán v rámci všech residencí, které vlastní hráč. Pokud hráč má na svém ostrově/residenci přidané hráče, limit se nijak nenavyšuje, jelikož daný region podlehá vlastnictví jednomu hráči. V případě spojení více hráčů, se počet všech spawnerů sčítá v regionu pro všechny hráče.</li>
											<li>V případě porušení pravidla o spawnerech, hráč ztrácí jakoukoliv náhradu za zničené spawnery, pozemek, itemy aj.</li>
											<li>Je zakázáno jakýmkoliv způsobem rozlévaní vody či lávy po mapě serveru.</li>
											<li>Je zakázáno stavět stavby, obvody či jiné věci, které budou lagovat server.</li>
											<li>Je zakázáno jakýmkoliv způsobem, ať už využití bugů, griefovat stavby serveru.</li>
											<li>Residence musí být minimálně od sebe vzdálené 10 bloků (neplatí pokud oba majitelé residencí souhlasí s blízkým propojením). Pokud toto nebude dodrženo a hráč, který má Residenci vytvořenou dříve než druhá, má nárok si vyžádat blízkou residenci smazat.</li>
											<li>Je zakázáno stavět stavby jako koncentrační tábory a fake shopy (kde hráč nevidí na cenu).</li>
											<li>Je zakázáno stavět nebo generovat obrázky (Stickers/ArtMap) s následujícími znaky: Hákový kříž, státní znak Sovětského svazu (srp).</li>
										</ol>
										<h3 className="card-title">Youtube & Twitch</h3>
										<ol>
											<li>CraftMania.cz nepodporuje YouTubery. Nelze si tedy vyžadovat práva na hodnost YouTuber.</li>
											<li>Kdokoliv může natáčet na serveru, pokud ale neporušuje ostatní pravidla serveru.</li>
											<li>V případě natáčení na serveru a následnému nahrání na YouTube, je povinností nahrávajícího dát do popisku správnou IP serveru - mc.craftmania.cz</li>
										</ol>
										<h3 className="card-title">Discord</h3>
										<ol>
											<li>Pro Discord platí stejná pravidla jako pro Forum a servery CraftMania.cz</li>
											<li>Na Discordu je zakázáno zneužívat označování (mention) jiných hráčů a skupin (včetně @everyone)</li>
										</ol>
										<h3 className="card-title">Fórum</h3>
										<ol>
											<li>Je přísně zakázán jakýkoliv spam příspěvků, nadávek, či jiného nevhodného chování na forum serveru.</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</PageContainer>
		);
	}
}

export default Pravidla;
