import { Component } from "react";
import * as React from "react";

const pig = require('./../images/pig_redirect.png');

interface ForumRedirectState {
	fixedLink: string | null;
}

class ForumRedirect extends Component<any, ForumRedirectState> {

	constructor(props: any) {
		super(props);
		this.state = {
			fixedLink: null
		};
	}

	public async componentDidMount() {
		console.log(this.props.location.pathname);
		const pathName = this.props.location.pathname;
		this.setState({
			fixedLink: 'https://forum.craftmania.cz' + pathName
		});
	}

	public render() {
		if (this.state.fixedLink === null) {
			return null;
		}
		return (
			<div>
				<section className="bg-home d-flex align-items-center">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-8 col-md-12 text-center">
								<img src={pig} className="img-fluid" alt=""/>
								<div className="text-dark mb-4 mt-4 error-page">Přesměrování na fórum</div>
								<p className="text-muted para-desc mx-auto">
									<span className="text-primary font-weight-bold">Používáš starý odkaz!
									</span> Od října 2020 se na naší hlavní doméně již nenachází fórum, link je proto neplatný. Jsme ale schopni ho za tebe opravit, stačí kliknout níže.
								</p>
							</div>
						</div>

						<div className="row">
							<div className="col-md-12 text-center">
								<a href={this.state.fixedLink} className="btn btn-primary mt-4 ml-2">Opravit a přejít na fórum</a>
							</div>
						</div>
					</div>
				</section>
			</div>
		);
	}

}

export default ForumRedirect;
